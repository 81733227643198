.drag-item  {
    position: relative;
    * {
        pointer-events: none;
    }

    &.dragging {
        z-index: 3;
    }
}
.drag-item {
    transition: transform 0.2s ease;
    touch-action: none; /* Importante para dispositivos táctiles */
}

.drag-item.dragging {
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    opacity: 0.8;
}