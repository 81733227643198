.card {
    border-radius: 10px;
    margin: 0 12px;

    &--default {
        background-color: #FFF;
    }

    &--secondary {
        background-color: $blue-02;

        &--outline {
            border: 2px solid $blue-01;
        }
    }

    &--tertiary {
        background-color: $blue-03;

        &--outline {
            border: 2px solid $blue-01;
        }
    }

    &--quaternary {
        background-color: $blue-04;
    }

    &--quinary {
        background-color: $blue-05;
    }
}