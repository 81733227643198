.game-over-confirm.small {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 258px;

    .title {
        display: block;
        position: relative;
        width: 100%;
    }
}