.gift-switch-container-host{
    display: flex;
    
}
.gift-switch-host .gift-switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px!important;
    
  margin: 11px;
    padding: 10px;
    gap: 6px;
    
}
.gift-switch .menu-user-info-title h4 {
    width: 150.72px!important;
}
.paragraph-induction{
    max-width: 335px!important;
    width: 333px!important;

}