.hint-background {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    .hint {
        display: flex;
        position: relative;
        align-items: center;
        width: 313px;
        height: 125px;
        border-radius: 15px;
        background-color: $blue-01;
        z-index: 2;
        bottom: 178px;

        @media (max-width: 768px) {
            bottom: 84px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: $blue-01 transparent transparent transparent;
        }
    }
}