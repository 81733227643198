.containr-director-by-host{
    display: flex;
    width: 410px;
    max-width: 410px;
    gap: 10px;
}


.text-number-one{
    font-size: xx-large;
    font-weight: bold;
}

.text-number-candidate{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 165px;
    max-width: 186px;
}