.custom-border-color {
   max-width: 413px !important;
   width: 340px;
   padding: 10px;
   margin-left: 10px;
}

.custom-border-color-room-a {
   background-color: #0D233B;
   max-width: 413px !important;
   width: 340px;
   padding: 0px;
   margin-left: 0px;
   height: 44px;
   border-radius: 6px !important;
}

.custom-border-color .info>.role {
   font-size: 10px;
}

.title-small {
   font-size: 11px;
   text-align: center;
   max-width: 147px;
   /* padding-top: 0px; */
   position: relative;
   /* margin: 0px 0px 0px 0px; */
   top: -27px;
}

.performance-button {
   width: 190px;
   max-width: 190px;
   /* bottom: 28px !important; */
   /* top: 0px; */
   margin: 0;
}

.description-text {
   max-width: 347px;

}


.menu-user-info-title>span {
   color: white !important;
   font-weight: 100 !important;
}

.medal-switch {
   margin: px 0px 0px 1px;
}

select option {
   border: none;
}

/*.checkbox--checkmark {
   top: 14px !important;
}*/


.epa-day-container {
   .medal-text p {
      width: 345px !important;
      max-width: 448px !important;
      text-align: center;
  }
  span.just-in-time-coordinator svg {
   text-align: center;
   margin-left: 153px;
}
   .menu-user-info {
      position: relative;
      width: 100%;
      max-width: 327px;
      justify-content: flex-start;
      margin: 10px;
      height: 126px;

      .menu-user-info-avatar {
         width: 36.6px;
         height: 36.6px;
         border: 3px solid #4dffdd !important;

         svg {
            width: 56.6px;
            height: 36.6px;
            margin-top: 5px;
         }
      }

      &.epa-requirements {
         background-color: #0F1B2B;
         padding: 9px;
         border-radius: 11px;

         .menu-user-info-avatar {
            width: 96px;
            height: 96px;
            border: 1px;

            svg {
               width: 116px;
               height: 96px;
               margin-top: 5px;
            }
         }

         .menu-user-info-title {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            font-weight: 100;
            color: white !important;
            margin: 0px 10px 31px 6px;

            .epa-requirements__status {
               display: flex;
               flex-direction: row;
               margin-top: 5px;

               .checkbox {
                  width: 40px;
                  padding-right: 0;
                  padding-left: 0;
               }
            }
         }
      }

   }

   .epa-requirements__status {

      .medal-switch {
         background-color: #0F1B2B;
         margin: 11px 0px 0px 1px;
      }
   }

   .medal-switch.select-candidate {
      margin: 17px;

      .select {
         background-color: #0F1B2B;
         color: white;


      }
   }
   .title-span{
      max-width: 284px;
      text-align: center;
      padding: 14px;
   }
   .tutorial-time-management__info{
      background-color: #202d47;
      border-radius: 7px;
      line-height: 1;
      /* max-width: 403px !important; */
      width: 369px;
      
   }
   .tutorial-time-management__end{
      background-color: #202d47;
      border-radius: 7px;
      line-height: 1;
      /* max-width: 403px !important; */
      width: 308px;
   }
   .end{
      
      width: 308px;
   }
   .medal-switch.disabled{
      margin: 6px;
   }
}