.level-influence {
    display: flex;
    font-size: 17px;
    margin: 6px 0 5px;
    font-weight: 400;
    line-height: 22px;
    flex-direction: column;
    
    &__level {
       font-weight: 600;
    }
}