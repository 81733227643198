@import './styles/mixins';

.button {
    background-color: $blue-01;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    box-shadow: 0px 0px 10px 0px $blue-opacity;
    border-style: none;
    border-radius: 15px;
    padding: 12px 0px;

    /* sizes */
    &--large {
        width: 343px;
        height: 48px;
    }

    &--small {
        width: 180px;
        height: 40px;
        line-height: 12px;
    }

    /* icon type */
    &--icon {
        position: relative;

        &__span {
            position: absolute;
            font-size: 24px;

            &--left {
                left: 12px
            }

            &--right {
                right: 19px
            }
        }
    }

    /* roles */
    &.candidate {
        @include button-role('candidate');
    }

    &.host {
        @include button-role('host');
    }

    &.coordinator {
        @include button-role('coordinator');
    }

    &.director {
        @include button-role('director');
    }

    /* styles */
    &--ghost {
        background-color: transparent;
        border: 2px solid $blue-01;
        color: $blue-01;
        box-shadow: none;

        &:active {
            background-color: rgba($blue-01, 0.2);
        }
    }

    &--primary {
        background-color: $blue-03;
        color: #FFF;
        box-shadow: none;

        &:active {
            background-color: $blue-03;
        }
    
    }

    &--abort {
        background-color: transparent;
        color: $noSent;
        box-shadow: none;
        border: none;
    }

    &:active {
        opacity: 0.5;
    }

    &:disabled {
        opacity: 0.3;
        box-shadow: none;
    }
}