.challenge-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .challenge {
        display: flex;
        min-width: 286px;
        height: 61px;
        font-size: 17px;
        font-weight: 400;
        border-radius: 30.5px;
        background-color: $blue-05;
        align-items: center;
        margin-bottom: 10px;
        z-index: 2;

        &--active {
            &--candidate {
                border: 2px solid $candidate;
            }

            &--host {
                border: 2px solid $host-02;
            }

            &--coordinator {
                border: 2px solid $coordinator;
            }

            &--director {
                border: 2px solid $director;
            }

            &--captain {
                border: 2px solid $captain;
            }
        }

        &--icon {
            width: 46px;
            height: 46px;
            background-position-x: 12px;
            margin-right: 10px;

            &--mic {
                background-image: $mic-image;
                background-repeat: no-repeat;
            }

            &--list {
                background-image: $list-box;
                background-repeat: no-repeat;
            }

            &--calendar {
                background-image: $calendar;
                background-repeat: no-repeat;
            }

            &--sync {
                background-image: $sync;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--rocket-icon {
                background-image: $rocket-icon;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 6px;
                width: 51px;
                margin-right: 5px;
            }

            &--victory-hand {
                background-image: $victory-hand;
                background-repeat: no-repeat;
                background-size: contain;
                width: 52px;
                margin-right: 5px;
            }

            &--host-money-hand {
                position: relative;
                background-image: $host-money-hand;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 6px;
                width: 56px;
                margin: 0;
            }

            &--host-screen-tools { 
                position: relative;
                background-image: $host-screen-tools;
                background-repeat: no-repeat;
                background-size: contain;
                width: 56px;
                margin: 0;
            }

            &--host-mail {
                position: relative;
                background-image: $host-mail;
                background-repeat: no-repeat;
                background-size: contain;
                width: 56px;
                margin: 0;
            }

            &--host-key-hand {
                position: relative;
                background-image: $host-key-hand;
                background-repeat: no-repeat;
                background-position: 2px;
                width: 56px;
                margin: 0;
            }

            &--good-hand {
                position: relative;
                background-image: $good-hand;
                background-repeat: no-repeat;
                background-position: 7px;
                background-size: contain;
            }

            &--coordinator-sync {
                background-image: $coordinator-sync;
                background-repeat: no-repeat;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--coordinator-calendar {
                background-image: $coordinator-calendar;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--check {
                background-image: $check-icon;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--coordinator-hand-key {
                background-image: $coordinator-hand-key;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--book {
                background-image: $book;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--coordinator-screen-tools {
                background-image: $coordinator-screen-tools;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }

            &--touch-screen-coordinator {
                background-image: $touch-screen-coordinator;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 8px;
                width: 51px;
                margin-right: 5px;
            }
        }

        &--progress {
            flex: 2;
            max-width: 191px;

            &--bar {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                .learn-bar {
                    width: 150px;
                }
            }
        }

    }

    .challenge--info {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 43px;
        padding-left: 19px;
        padding-right: 5px;
        background-color: $blue-04;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        position: relative;
        top: -6px;
        left: -9px;
        z-index: 1;

        .icon {
            display: flex;
            width: 32px;
            height: 32px;
            font-size: 30px;
            align-items: center;
            justify-content: center;
            background-color: #0063DD;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.5;
    }

}