.login-federation {
    margin-bottom: 20px;

    .description {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        width: 100%;
        text-align: center;
        display: flex;
        margin-bottom: 22px;
        gap:14px;


        &::before {
            content: '';
            display: block;
            flex: 1;
            height: 1px;
            background-color: #fff;
            margin: 10px 0; 
        }

        &::after {
            content: '';
            display: block;
            flex: 1;
            height: 1px;
            background-color: #fff;
            margin: 10px 0;
        }
    }

    .apps {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;
        gap: 13px;
    }

}