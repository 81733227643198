.learn-bar {
    display: flex;
    position: relative;
    width: 100%;
    height: 8px;
    background-color: $background-bar-color;
    border-radius: 4px;

    &--small {
        height: 4px;
        border-radius: 2px;
    }

    &--large {
        height: 15px;
        border-radius: 7.5px;

        .learn-bar__progress {
            border-radius: 7.5px;
        }
    }

    &__progress {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transform-origin: left center;
        transform: scale(0);
        transition: transform 1s ease;

        &--candidate {
            background-color: $candidate;
        }

        &--host {
            background-color: $host-02;
        }

        &--coordinator {
            background-color: $coordinator;
        }

        &--director {
            background-color: $director;
        }

        &--captain {
            background-color: $captain;
        }
    }
}