.landing-page {
    width: 100%;

    &-main {
        display: block;
    }

    &-carousel {
        overflow: hidden;
        height: 610px;

        &--swiper {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &-benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $blue-02;
        color: #FFF;

        &__title {
            max-width: 862px;
            font-size: 64px;
            margin: 0 0 28px 0;
            text-align: center;
        }

        &__cards {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin: 0;
            padding: 0;
            list-style: none;
            gap: 16px;
            margin-bottom: 40px;
            justify-content: center;

            &--card {
                display: flex;
                position: relative;
                flex-direction: column;
                align-items: center;
                width: calc(100% - 80px);
                max-width: 363px;
                height: 310px;
                padding: 33px 30px;
                margin: 0;
                text-align: center;
                overflow: hidden;
                box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);

                .circle {
                    position: absolute;
                    width: 884px;
                    height: 617px;
                    background-color: $blue-03;
                    border-radius: 50%;
                    top: -475px;
                    left: -381px;
                    z-index: 2;

                    &.secondary {
                        background: $background-circle-secondary;
                        opacity: 0.5;

                    }
                }

                &-title {
                    width: 310px;
                    font-size: 24px;
                    font-weight: 700;
                }
            }
        }
    }

    &-our-platform {

        &__extra-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #FFF;
            gap: 50px;
            margin-top: 20px;

            &--image {
                position: relative;
                top: -35px;
            }

            &--text {
                display: flex;
                position: relative;
                flex-direction: column;
                width: calc(100% - 60px);
                max-width: 376px;
                height: 126px;
                align-items: flex-start;
                justify-content: flex-start;
                background-color: $blue-03;
                border: 1px solid $blue-01;
                border-radius: 20px;
                font-weight: 400;
                padding: 20px 23px;
                z-index: 1;

                &--triangle {
                    position: absolute;
                    left: -33px;
                    z-index: 0;
                }

                &--image {
                    position: absolute;
                    top: -35px;
                    right: 20px;
                }

                &--name {
                    font-weight: 700;
                }

                &--role {
                    margin-bottom: 12px;
                }

                &--stars {
                    position: relative;
                    left: -22px;
                }

                &--description {
                    margin-top: 12px;
                }
            }
        }
    }

    &-founders {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $blue-02;
        color: #FFF;

        &__title {
            max-width: 862px;
            font-size: 64px;
            margin: 79px 0 28px 0;
            text-align: center;
        }

        &__cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 61px;

            &--card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &--image {
                    border-top-left-radius: inherit;
                    border-top-right-radius: inherit;

                    img {
                        border-radius: inherit;
                    }
                }

                &--content {
                    padding-left: 16px;

                    &--name {
                        font-size: 16px;
                        margin-bottom: 6px;
                        text-align: left;
                    }

                    &--role {
                        margin-bottom: 13px;
                    }

                    &--social-media {
                        &--list {
                            &--icons {
                                display: flex;
                                position: relative;
                                flex-direction: row;
                                list-style: none;
                                color: lightgray;
                                margin-top: 13px;
                                padding: 0;
                                font-size: 30px;
                                left: -5px;

                                &--item {
                                    a {
                                        text-decoration: none;
                                        color: #FFF;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media(max-width: 500px) {
    .landing-page {
        &-carousel {
            height: auto;

            .swiper-slide {
                height: auto;
            }
        }

        &-our-platform {
            &__extra-content {
                position: relative;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 40px;
                margin-bottom: 58px;

                &--image {
                    position: absolute;
                    width: 74px;
                    z-index: 2;
                    right: 32px;
                }

                &--text {
                    &--triangle {
                        display: none;
                    }

                    &--image {
                        display: none;
                    }
                }
            }
        }
    }
}