.scale-item {
    position: relative;
    display: flex;
    min-height: 65px;
    max-width: 370px;

    .selector {
        position: absolute;
        background-position: center;
        -webkit-mask-image: $avatar-icon;
        mask-image: $avatar-icon;
        width: 47px;
        height: 60px;
        transform: rotate(90deg);
        z-index: 5;
    }

    &__candidate {
        border-color: $candidate;

        &.active {
            color: $candidate;

            .selector {
                background-color: $candidate;
            }
        }
    }

    &__host {
        border-color: $host-02;

        &.active {
            color: $host-02;

            .selector {
                background-color: $host-02;
            }
        }
    }

    &__coordinator {
        border-color: $coordinator;

        &.active {
            color: $coordinator;

            .selector {
                background-color: $coordinator;
            }
        }
    }

    &__director {
        border-color: $director;

        &.active {
            color: $director;

            .selector {
                background-color: $director;
            }
        }
    }

    &__captain {
        border-color: $captain;

        &.active {
            color: $captain;

            .selector {
                background-color: $captain;
            }
        }
    }

    &--top {
        display: flex;
        border: 1px solid;
        border-right: 13.14px solid;
        padding-right: 13px;
        border-radius: 5px;
        flex-direction: column;
        justify-content: center;
        gap: 11px;
        font-size: 14px;
        font-weight: 600;
        border-color: inherit;

        div {
            display: flex;
            flex-direction: row;
        }

        &__icon {
            display: inline-block;
            transform: rotate(90deg);
            margin-left: 4px;
        }

        &--role {
            margin-left: 6px;
        }

        &--level {
            margin-left: 6px;
        }
    }

    &--bottom {
        display: flex;
        position: relative;
        border: 1px solid;
        border-left: 6px solid;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        margin-right: 6.14px;
        font-size: 17px;
        font-weight: 500;
        border-color: inherit;
        color: #fff;
        width: 51px;

        &--description {
            transform: rotate(90deg);
        }
    }
}