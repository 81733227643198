.agreements-modal {
    &.coordinator {
        height: 447px;
    }
}

.epa-end-day-container {

    .paragraph.gift-text {
        max-width: 323px;
    }
    .no-margin {
        margin-bottom: 0;
    }

    &.host-alignment-day-container {

        .gift-switch-container {
            gap: 10px;
        }
    }

    .foundation-item {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        max-width: 343px;
        width: 100%;
        margin-bottom: 20px;
        gap: 5px
    }

    .title {
        margin-bottom: 20px !important;
    }

    .paragraph.background-p {
        align-items: center;
        background-color: $blue-04;
        border-radius: 10px;
        z-index: 1;
        height: 87px;
        padding: 12px 23px;
        max-width: 294px;
    }

    .buy-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 343px;
        height: 93px;
        background-color: $blue-02;
        border-radius: 10px;
        gap: 20px;
        margin-bottom: 16px;

        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            &__name {
                .icon-gift {
                    color: $blue-01;
                }
            }

            &__price {
                font-weight: 700;

                .sale {
                    font-size: 12px;
                    color: $coordinator;
                }
            }
        }

        &.disabled {
            opacity: 0.7;
            pointer-events: none;
        }
    }

    .buy-table {
        border-spacing: 16px;
        border-collapse: separate;

        th {
            font-weight: 700;
        }

        .border {
            border: 1px solid $blue-01;
            background-color: $blue-03;
            border-radius: 10px;
            height: 48px;
            padding: 0 9px;

            span {
                opacity: 0.6;
            }
        }
    }

    .form {
        align-items: center;

        .card-form {
            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                span {
                    font-size: 14px;
                    font-weight: 700;
                    max-width: 74px;
                    text-align: right;
                }

                .text-field {
                    margin-top: 0;
                    max-width: 253px;
                }

                &__exp_month {
                    &+.text-field {
                        width: 74px;
                        text-align: center;

                        input {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                &__exp_year {
                    &+.text-field {
                        width: 84px;
                        text-align: center;

                        input {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                &__phone {
                    width: 74px;

                    &+.text-field {
                        width: 280px;
                        max-width: 293px;
                        text-align: center;

                        input {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                &.cvc {
                    align-self: flex-end;
                    justify-content: flex-end;

                    .card-form__item__cvc {

                        &+.text-field {
                            width: 163px;
                            text-align: center;

                            input {
                                padding: 0;
                                text-align: center;
                            }
                        }
                    }
                }

                &.identification {
                    .select {
                        width: 74px;
                    }

                    .text-field {
                        width: 163px;
                    }
                }
            }
        }
    }

    &.host {
        .card {
            margin-bottom: 16px;
            gap: 2px;

            &-title {
                display: block;
                width: 343px;
                padding: 12px 0;
            }

            &-item {
                display: flex;
                flex-direction: column;
                background-color: $blue-04;
                padding: 9px 0 15px;
                margin-bottom: 2px;

                &__title {
                    opacity: 0.6;
                }

                &.last {
                    border-radius: 0 0 10px 10px;
                    margin-bottom: 0;
                }
            }

            &+.button {
                margin-top: 50px;

                &+.button {
                    margin-top: 25px;
                }
            }
        }
    }

    .paragraph {
        .primary {
            font-size: 32px;
            color: $blue-01;
        }
    }

    &-card {
        margin-bottom: 40px !important;
    }

    &-button {
        position: relative !important;
    }
}