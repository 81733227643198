.validator-container {
    height: 100%;

    
    .background__content {
        &__title {
            margin-top: 11px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            letter-spacing: 2.5px;
        }

        &__subtitle {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            padding: 5px;
            text-align: center;
        }
    }

    .button {
        z-index: 1;
    }

}