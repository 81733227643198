.validator-info-page {
    .tabs {
        margin-top: 20px;
    }

    .modal-container {

        .modal {
            height: 247px;
            align-items: center;
            justify-content: center;

            .modal-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .title {
                    display: block;
                    width: 343px;
                    position: relative;
                    text-align: center;
                    margin-top: 20px;
                }

                .buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 41px;

                    .button {
                        width: 116px;
                        height: 40px;
                    }
                }
            }
        }
    }
}