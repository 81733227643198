.missions-page {
    height: 100%;

    &.auto {
        height: auto;
    }

    #init {
        position: relative;
        margin-top: 150px;
        bottom: 40px;
        z-index: 1;
    }

    .card-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 16px;
        min-width: 287px;

        .item {
            display: flex;
            flex-direction: row;
            gap: 6px;

            .icon {
                color: $blue-01;
            }
        }

        &.medals {
            margin-top: 1px;
        }
    }

    .carousel-slide {
        display: flex;
        flex-direction: column;

        .badge-target {
            align-self: center;
        }

        .megaphone {
            align-self: center;
        }

        .button {
            align-self: center;
            margin-bottom: 20px;
        }
    }

    .medal-modal {
        height: 370px;
    }
}

.level-keys {
    text-align: center;
    
    .inactive {
        .h1 {
            opacity: 0.5;
        }

        .h2 {
            opacity: 0.5;
        }

        .h3 {
            opacity: 0.5;
        }
    }
}


.medals-title {
    margin-top: 43px;
    margin-bottom: 40px;
}

.goal-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;
    max-width: 311px;

    .progress {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }
}