.agreements-honon-ok-modal{
  &.director {
    height: 74%;
}
}
.agreements-hand-modal {
    &.director {
        height: 446px;
    }
}
// .agreements-modal.new-director-modal{
//       height: 446px;
  
// }
.agreements-checkbox-modal{
  height: 440px;
  background-color: #0D233B!important;
  &.modal{
    background-color:red!important;
  }
  &.modal-background{
    background-color: #0D233B!important;
  }
}
.agreements-gif-modal{
  
  &.director {
      height:560px ;
    }
}
.modal-container {
    overflow: hidden;
}
.epa-end-day-director {
 
.abot{
    bottom: 100px;
}
    .paragraph.gift-text {
        max-width: 323px;
    }
    .no-margin {
        margin-bottom: 0;
    }
    .span-phrase {
        height: 264px;
    }
    .phrase-container {
        margin: 0px;
        /* bottom: 15px; */
        top: 0px;
    }
    &.host-alignment-day-container {

        .gift-switch-container {
            gap: 10px;
        }
    }

    .foundation-item {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        max-width: 343px;
        width: 100%;
        margin-bottom: 20px;
        gap: 5px
    }

    .title {
        margin-bottom: 20px !important;
    }

    .paragraph.background-p {
        align-items: center;
        background-color: #202D47;
        border-radius: 10px;
        z-index: 1;
        height: 87px;
        padding: 12px 23px;
        max-width: 294px;
    }

    .buy-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 343px;
        height: 93px;
        background-color: #0F1B2B;
        border-radius: 10px;
        gap: 20px;
        margin-bottom: 16px;

        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            &__name {
                .icon-gift {
                    color:#0063DD;
                }
            }

            &__price {
                font-weight: 700;

                .sale {
                    font-size: 12px;
                    color: #FFEB00!important;
                }
            }
        }

        &.disabled {
            opacity: 0.7;
            pointer-events: none;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        gap: 8px;
        list-style: none;
        padding-left: 20px;

        li {
            padding: 0 10px;
            word-break: break-all;
            text-align: justify;
            text-indent: -20px;

            &::before {
                content: '\e802';
                font-family: 'acdc-font-icons', sans-serif;
                color: #0063DD;
                margin-right: 5px;
                font-size: 10px;
            }

            &::marker {
                padding-right: 10px;
            }
        }

    }
    .buy-table {
        border-spacing: 16px;
        border-collapse: separate;

        th {
            font-weight: 700;
        }

        .border {
            border: 1px solid #0063DD;
            background-color: #0D233B;
            border-radius: 10px;
            height: 48px;
            padding: 0 9px;

            span {
                opacity: 0.6;
            }
        }
    }

    .form {
        align-items: center;

        .card-form {
            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                span {
                    font-size: 14px;
                    font-weight: 700;
                    max-width: 74px;
                    text-align: right;
                }

                .text-field {
                    margin-top: 0;
                    max-width: 253px;
                }

                &__exp_month {
                    &+.text-field {
                        width: 74px;
                        text-align: center;

                        input {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                &__exp_year {
                    &+.text-field {
                        width: 84px;
                        text-align: center;

                        input {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                &__phone {
                    width: 74px;

                    &+.text-field {
                        width: 280px;
                        max-width: 293px;
                        text-align: center;

                        input {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                &.cvc {
                    align-self: flex-end;
                    justify-content: flex-end;

                    .card-form__item__cvc {

                        &+.text-field {
                            width: 163px;
                            text-align: center;

                            input {
                                padding: 0;
                                text-align: center;
                            }
                        }
                    }
                }

                &.identification {
                    .select {
                        width: 74px;
                    }

                    .text-field {
                        width: 163px;
                    }
                }
            }
        }
    }

    &.host {
        .card {
            margin-bottom: 16px;
            gap: 2px;

            &-title {
                display: block;
                width: 343px;
                padding: 12px 0;
            }

            &-item {
                display: flex;
                flex-direction: column;
                background-color: #202D47;
                padding: 9px 0 15px;
                margin-bottom: 2px;

                &__title {
                    opacity: 0.6;
                }

                &.last {
                    border-radius: 0 0 10px 10px;
                    margin-bottom: 0;
                }
            }

            /*&+.button {
                margin-top: 50px;
                

                &+.button {
                    margin-top: 25px;

                }
            }*/
        }
    }
 
    .paragraph {
        max-width: 327px;
      
        .primary {
            font-size: 32px;
            color: #0063DD;
        }
    }
    .btn {
        font-size: 1rem;
        font-weight: bold;
        padding: 15px 30px;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
        margin: 10px;
        width: 200px;
    }
    
    .primary {
        //background-color: #0055ff;
        color: white;
        border: none;
    }
    
    .primary:hover {
        background-color: #003bb5;
    }
    
    .outlined {
        background-color: transparent;
        color: #0055ff;
        border: 2px solid #0055ff;
    }
    
    .outlined:hover {
        background-color: rgba(0, 85, 255, 0.1);
    }

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    button.button.button--large.button--default {
        margin: 51px;
    }
}

}
.honor-button {
  margin-left: 72px;
  margin-top: 23px;
}
.hand-honor-director{
  margin: 15px 10px 12px 94px;
}
// Variables
$background-dark: #0c1b2e;
$background-light: #13253b;
$text-color-light: #ffffff;
$primary-color: #298bff;
$secondary-color: #ff3c6c;
$border-radius: 12px;
$font-family: 'Arial', sans-serif;

// Global styles


.card-host-header {
  display: flex;
}
.coordinator-info {
  text-align: left;
}

.coordinator-name {
  font-size: 1rem;
  color: white;
  padding-left: 0px;
  margin: 5px;
  top: 10px !important;
  padding-top: 9px;


}

// Card styles
.card-coordinator{
  background-color: #0c1b2e;
}
.card-host{
  background-color: #0c1b2e;
}

label.checkbox.option-checkbox-honor {
  left: 24px;
  margin: 0;
  top: 10px;
}
.card-host {
    // background-color: $background-light;
    /*border-radius: $border-radius;
    padding: 15px;
    width: 337px;*/

        margin-bottom: 70px;
    &.card-host-border{
      border:solid 2px #00f;
      margin-top: 10px;
    }
    &.card-host-header {
      display: flex;
  }
  
    &.coordinator {
      
        margin: 5px;
    }
    .card-coordinator{
      background-color: #0c1b2e;
    }
    &.host {
        border: none;
        margin: 5px;
    }

    .header {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0px 85px 0px 10px;
        .profile-pic {
            width: 69px;
            height: 69px;
            border-radius: 50%;
            margin-right: 10px;
          
        }

        .info {
            
            h2 {
                margin: 0;
                font-size: 16px;
                color: $text-color-light;
                width: 203px;
                text-align: justify;
            }

            p {
                margin: 0;
                font-size: 14px;
                width: 156px;
                text-align: justify;
              
            }
        }

        i {
            margin-left: auto;
            color: $text-color-light;
            font-size: 18px;
            cursor: pointer;
        }
    }

  
        .checkbox {
            display: flex;
            align-items: baseline;
            position: relative;
            cursor: pointer;
            font-size: 14px;
            padding: 19px 50px;
            /* margin-bottom: 10px; */
            margin-left: 37px;
            align-content: flex-end;
            width: 290px;
            max-width: 290px;
               /* margin-bottom: 10px; */
    // padding: 17px 13px 5px 48px;
    // margin: -10px 0px 4px 0px;
    // width: 294px;
        
            i {
                margin-right: 10px;
                color: $primary-color;
                font-size: 18px;
            }
        }
    
}



.event-container {
    width: 386px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }
  
  .event {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
  }
  .event-possible{
    display: flex;
    justify-content: space-between;
    margin: 0px 0;
    padding: 10px;
    border-radius: 5px;
   
    .checkbox{
        display: flex;
    align-items: baseline;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    padding: 20px 1px;
    /* margin-bottom: 15px; */
    margin-left: 17px;
    /* align-content: flex-end; */
    width: 16px;
    max-width: 12px;
        i {
            margin-right: 10px;
            color: $primary-color;
            font-size: 18px;
        }
    }
    .event-possible-title {
        background: #13253b;
        width: 176px;
        max-width: 207px;
        /* padding: 0px 23px 14px 6px; */
        border-radius: 5px;
        text-align: center;
        font-size: 13px;
        height: 29px;
        margin-top: 11px;
        padding-top: 10px;
        margin-left: 19px;
        border: none;
    }
  
    .event-possible-hour {
        width: 128px;
        max-width: 170px;
        border-radius: 5px;
        text-align: center;
        background: #13253b;
        font-size: 14px;
        margin-top: 11px;
        padding-top: 10px;
       color: #ffffff!important;
        height: 29px;
        border: none;
        /* text-align: center; */
    }

    .event-possible-span{
        font-size: 11px;
        padding: 0px 183px 10px 10px;
      
    }
  }
  .event span {
    font-size: 14px;
  }
  .event-title {
    
        background: #13253b;
        width: 169px;
        max-width: 170px;
        padding: 10px 10px 10px 0px;
        border-radius: 5px;
        text-align: center;
    
  }
  .event-date{
    width: 169px;
    max-width: 170px;
    padding: 10px 10px 10px 0px;
    border-radius: 5px;
    text-align: center;
    border: solid 1px #0066DD;
    background: #0F1B2B;
    
  }
  .edit-icon {
    color: #00f;
    text-decoration: none;
    margin-left: 10px;
  }
  
  .edit-icon:hover {
    text-decoration: underline;
  }
  .event-modal-text{
    text-align: center;
    padding: 10px 10px 0px 77px;
  }


  .user-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0d2744;
    border-radius: 25px;
    padding: 10px 20px;
    width: 301px;
    margin: 10px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-avatar {
   
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
  }
  
  .user-name {
    margin: 0;
    font-size: 16px;
  }
  
  .user-status {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .status-dot {
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .status-text {
    font-size: 12px;
    color: #ccc;
  }
  
  .toggle-switch {
    position: relative;
  }
  
  .switch-input {
    display: none;
  }
  
  .switch-label {
    display: inline-block;
    width: 50px;
    height: 25px;
    background-color: #666;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
  }
  
  .switch-label::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2.5px;
    left: 3px;
    transition: 0.3s;
  }
  
  .switch-input:checked + .switch-label::after {
    transform: translateX(25px);
  }
  
  .switch-input:checked + .switch-label {
    background-color: #333;
  }

  .phrase-container {
    bottom: 12px;
}
.avatar-host{
    position: relative;
    width: 391px; // Ancho de la imagen de fondo
   // height: 300px; // Alto de la imagen de fondo
    text-align: center;
}
.avatar-host-gif{
    position: absolute;
    width: 350px;
    /* height: 300px; */
    top: 21%;
   
}
 
.p-size{
    width: 340px;
    max-width: 340px !important;
    padding-top: 12px;
}

.fontWeight{
  font-weight: 600;
}

.user-list-checkbox {
    width: 350px;
  }
  
  .user-card-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0d233b;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
  .user-info-checkbox {
    display: flex;
    align-items: center;
  }
  
  .user-avatar-checkbox {
  
    margin-right: 15px;
  }
  
  .user-details-checkbox {
    display: flex;
    flex-direction: column;
  }
  
  .user-name-checkbox {
    font-size: 13px;
    color: #fff;
    position: relative;
    width: 186px;
    max-width: 300px;
    margin-bottom: 0px;
    height: 36px;
  }
  .coordinator-checkbox {
    top: 25px;
    position: relative;
    left: 10px;
  }
  .user-action-checkbox {
    font-size: 12px;
    margin: 0;
    color: #ccc;
    background: #0F1B2B;
    width: 188px;
    border-radius: 4px;
    text-align: center;
    height: 30px;
  }
  .user-action-checkbox-2 {
    font-size: 12px;
    margin: 0;
    color: #ccc;
    background: #0F1B2B;
    width: 187px;
    border-radius: 4px;
    text-align: center;
    height: 30px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .action-icon-checkbox {
        position: relative;
        left: 16px;
        margin: 2px;
        top:7px;
  }
  
  .user-status-checkbox {
    border-radius: 5px;
    font-size: 14px;
    color: white;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    width: 35px;
    height: 35px;

  }
  
  .user-status-checkbox-2 {
    position: relative;
    /* display: flex; */
    margin-top: 19px;
    /* margin-left: 0px; */
    /* margin: 0px; */
    justify-content: space-between;
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    /* align-content: flex-start; */
    left: -11px;
    top: -1px;
  }

  
  .status-check-checkbox {
    font-size: 20px;
    color: #00f;
    background: #0F1B2B;
    width: 34px;
    height: 34px;
    text-align: center;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    }


.avatar-modal-host {
    position: relative;
    width: 324px;
    height: 237px;
           
    text-align: center;
}
.avatar-modal-host-gif{
    position: absolute;
    width: 348px;
    /* height: 300px; */
    top: 13%;
    left: 1%;
}
.avatar-img-modal{
    width: 150px;
}


  .user-container-hungry {
    width: 350px;
  }
  
  .user-card-hungry {
    background-color: #0d2744;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    /*background-color: #0d2744;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;*/
  }
  
  .user-header-hungry {
    display: flex;
    align-items: center;
    width: 323px;
    max-width: 323px;
  }
  
  .avatar-hambre {
    margin-right: 10px;
  }
  
  .user-details-hungry {
    display: flex;
    flex-direction: column;
  }
  
  .user-name-hungry {
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-weight: bold;
  }
  
  .user-role-hungry {
    font-size: 16px;
    margin: 0;
    color: #ccc;
  }
  
  .dropdown-icon-hambre {
    font-size: 20px;
    color: #fff;
  }
  
  .epa-section-hungry {
    background-color: #0d2744;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .epa-title-hungry {
    font-size: 16px;
    color: #fff;
  }
  
  .epa-details-hambre {
    display: flex;
    justify-content: space-between;
    background-color: #103a63;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .date-hambre, .time-hambre {
    font-size: 12px;
    color: #fff;
  }
  .card-body-epa{
    display: flex;
  }
  .epa-hungry-date{
    width: 200px;
    border-radius: 5px;
    margin-left: 9px;
    text-align: center;
    color: white;
    background: #0f1b2b;
    border: solid 2px #0063dd;
    height: 30px;
    font-size: 16px;
  }
  .epa-hungry-hour{
    width: 80px;
    text-align: center;
    margin-left: 8px;
    color: white;
    background: #0f1b2b;
    border: solid 2px #0063dd;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;

  }
  .task-section-hambre {
    background-color: #0d2744;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
  }
  
  .task-hambre {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkmark-hambre {
    font-size: 18px;
    color: #00f;
  }
  
  .task-count-hambre {
    font-size: 14px;
    font-weight: bold;
  }
  
  .validate-btn-hambre {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .validate-btn-hambre:hover {
    background-color: #0056b3;
  }
  .card-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 305px;
    margin: 10px 5px 12px 11px;
}
.btn-date.accept {
  
  color: white;
  margin: 0px;
  background: #0063dd;
  width: 101px;
  max-width: 120px;
  text-align: center;
  height: 33px;
  max-height: 33px;
  padding-top: 10px;
  border-radius: 16px;
}
.btn-date.edit {
  background-color: transparent;
  color: red;
  text-decoration: underline;
}

.accept-modal {
  max-width: 80px;
  width: 80px;
  height: 36px;
  border-radius: 12px;
  background: #0063dd;
  border: none;
  color: white;
}


.user-card-h {
    background-color: #0d2744;
    border-radius: 10px;
    padding: 15px;
    width: 323px;
    margin-bottom: 6px;
  }
  
  .user-header-h {
    display: flex;
    align-items: center;
   
  }
  
  .avatar-h {
    margin-right: 10px;
  }
  
  .user-info-h {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
  }
  
  .user-name-h {
    margin: 0;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }
  
  .user-role-h {
    margin: 5px 0 0;
    font-size: 16px;
    color: #ccc;
  }
  
  .task-section-h {
    
      /* margin-bottom: 15px; */
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
  }
  
  .task-h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
   
  }
  
  .checkbox-container-h {
    position: relative;
  }
  
  .checkmark-h {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border-radius: 4px;
  }
  
  .checkmark-h:before {
    content: "✔";
    position: absolute;
    left: 3px;
    top: 0;
    font-size: 16px;
    color: white;
  }
  
  .task-count-h {
   
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background: #10151f;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tastk-span{
    font-size: 16px;
    color: white;
    background: #10151f;
    width: 266px;
    height: 34px;
    padding-left: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 6px;
  }
  .validate-button-h {
    width: 53%;
    /* padding: 13px; */
    background-color: #007bff;
    border: none;
    border-radius: 13px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    /* text-align: center; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 14px;
    height: 39px;
  }
  
  .validate-button-h:hover {
    background-color: #0056b3;
  }
  span.validate-name {
      text-align: center;
      /* padding: 71px; */
      line-height: 3;
      background: #0f1b2b;
      height: 38px;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      flex-direction: row;
      border-radius: 5px;
      width: 211px;
      justify-content: center;
  
}

.date-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #343447;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.event-possible-checkbock {
  top: 16px;
  cursor: pointer;
}


h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: white;
  text-align: center;
}

.date-info {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  color: #ffffff;
}

.date-info span {
  font-size: 1rem;
  font-weight: bold;
}

p {
  // margin: 5px 0 0;
  font-size: 0.9rem;
  // color: #bbb;
}
input.event-possible-title {
  color: white;
}
input.event-possible-hour{
  color: #000!important;
}
/*
input[type="checkbox"]:checked + label {
  background-color: #0055ff;
  border-color: #0055ff;
}*/

.date-option:hover {
  background-color: #4a4a5a;
}

//gift
.title-gift-span {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-gift{
display: flex;
    align-content: center;
    flex-wrap: wrap;
    width: 163px;
    height: 46px;
    justify-content: center;
    margin-left: 26px;
    cursor: pointer;
}
.btn-gift-0{
  display: flex;
      align-content: center;
      flex-wrap: wrap;
      width: 163px;
      height: 46px;
      justify-content: center;
      margin-left: 80px;
      cursor: pointer;
  }
.honor-text-table{
  position: absolute;
  top: 49px;
  padding-left: 26px;
  width: 295px;
  font-size: 16px;
}
.gift-text{
  position: absolute;
  top: 49px;
  padding-left: 40px;
  width: 303px;
  font-size: 16px;
}
.honor-group{
  margin-top: 87px;
}
.gift-text-phrase {
  padding: 12px 4px 0px 0px;
}
.btn-gift-2 {
  margin-top: 19px !important;
}

//hangry
.text-hungry{
  max-width: 288px;
  width: 284px;
  top: 49px;
  padding-left: 24px;
  position: absolute;
}

.button.director{
  background-color: #FFCE1F!important;
}


.lista-validadores {
  width: 300px; /* Ajustar el ancho de la lista */
}

.validador {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px; /* Separación entre validadores */
}

.checkbox-validador {
  width: 24px;
  height: 24px;
  background-color: #0d2744; /* Color del checkbox */
  border-radius: 5px;
  margin-right: 15px; /* Espaciado entre checkbox y texto */
}

.nombre-validador {
  flex-grow: 1;
  font-size: 18px;
  color: #fff;
  background:#0d2744;
  height: 39px;
  display: flex;
  /* flex-wrap: wrap; */
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding-left: 10px;
}


.text-honor{
  width: 356px !important;
  max-width: 356px!important;
}
.card-honor {
  // background-color: $background-light;
  border-radius: $border-radius;
  padding: 15px;
  width: 326px;
}

.honor-box-checkbox {
  display: flex;
  height: 53px;
  /* margin-top: 17px; */
}
span.honor-option-text {
  display: flex;
  align-items: center;
}

span.title-honor {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}
.i-honor{
  font-size: 38px;
  /* margin: 0; */
  /* display: flex; */
  position: relative;
  top: -9px;
  left: -1px;
}
.li-honor{
  display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.user-status-checkbox-3 {
  border-radius: 5px;
  font-size: 14px;
  color: white;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 35px;
  height: 35px;
top:25px;
left: 5px;
}
.status-check-checkbox-3 {
  font-size: 20px;
  color: #00f;
  background: #0F1B2B;
  width: 34px;
  height: 31px;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 19px;
}

.card-host-captain {
  border-radius: 12px;
  padding: 15px;
  width: 337px;
  margin-bottom: 13px;
  background-color: #0c1b2e;
}

.card-host-border-style{
    width: 350px;
    max-width: 3450px;
    border-radius: 13px;

}
.phrase-container-height{
  height: 90px !important;
  margin-bottom: 0px !important;
  margin: 0;
}
.book-top{
  top: -43px !important;
}
.paragraph-justify{
  max-width: 340px!important;
  width: 340px !important;
}
.paragraph-pdl-left{
  margin-left: -47px;
}
.honor-width{
  max-width: 344px !important;
  width: 344px !important;
}
.host-box{
  background: #202D47;
  max-width: 367px;
  width: 366px;
  height: 54px;
  /* padding-top: 13px; */
  display: flex;
  justify-content: space-evenly;
  border-radius: 6px;
  margin-left: 10px;
}
.host-box-paragraph{
  width: 350px !important;
  max-width: 350px;
}
.event-possible-hour{
  color: white !important;;
}

span.close-new-director {
  position: absolute;
  top: 0px;
  right: -47px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 26px;
  cursor: pointer;
  outline: none;
}

.event-possible > input[type="date"]::-webkit-calendar-picker-indicator {
  color: white;
  filter: invert(1);
  font-size: 24px;
}
.event-possible > input[type="time"]::-webkit-calendar-picker-indicator {
  color: white;
  filter: invert(1);
  font-size: 24px;
}

.my-styles{
  margin-left: 28px !important;
  width: 265px !important;
  max-width: 345px;
  text-align: left;
  padding-left: 10px;
  background: #13253b;

  /* padding: 0px 23px 14px 6px; */
  border-radius: 5px;

  font-size: 13px;
  height: 29px;
  margin-top: 11px;
  padding-top: 10px;

  border: none;
}
.calendar-icon-p{
  margin-top: 20px;
}
.price-coor{
  text-align: left;
}

.minute-option:not([data-minute="0"]):not([data-minute="30"]) {
  display: none;
}