header {
    display: flex;
    position: relative;
    margin: 70px 37px 0;
    align-items: center;
    width: 100%;
    max-width: 338px;

    .logo {
        display: flex;
        width: 100%;
        height: 51.4px;
        background-repeat: no-repeat;
        background-position: center;
        mask-size: contain;
        align-items: center;
        justify-content: center;

        &--candidate {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $candidate;
                }

                path[fill="#313131"] {
                    fill: $candidate-circle;
                }
            }
        }

        &--host {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $host-02;
                }

                path[fill="#313131"] {
                    fill: $candidate;
                }
            }
        }

        &--coordinator {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $coordinator;
                }

                path[fill="#313131"] {
                    fill: $host-02;
                }
            }
        }

        &--director {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $director;
                }

                path[fill="#313131"] {
                    fill: $coordinator;
                }
            }
        }

        &--captain {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $captain;
                }

                path[fill="#313131"] {
                    fill: $director;
                }
            }
        }
    }

    .icon {
        position: absolute;
        font-size: 26px;
        z-index: 2;

        &.right {
            right: 0;
        }

        &.left {
            left: 0;
        }
    }
}

.title {
    text-align: center;
    margin: 10px 0 20px;
}

.subtitle {
    margin-top: 50px
}

.pdl__purpose__text, .pdl__destination__text, .pdl__legacy__text  {
    text-wrap: wrap;
    word-wrap: break-word;
}