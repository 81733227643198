.card-copy-key {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 375px;
    padding-bottom: 24px;
    margin-top: 20px;

    &__key {
        width: 100%;
        background-color: $blue-03;
        border-radius: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        padding: 9px 0;
        margin-bottom: 21px;
        max-width: 313px;
    }

    .paragraph {
        margin-bottom: 7.5px;
        font-size: 16px;

        b {
            font-weight: 600;
        }

        &--xs {
            font-size: 14px;
            margin-top: 10px;
        }

        .icon-info {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $blue-01;
            font-size: 17px;
            opacity: 1;
            z-index: 1;
        }

        &.first-paragraph {
            margin-bottom: 16px !important;
        }

        &.second-paragraph {
            margin-top: 21px !important;
        }
    }
}