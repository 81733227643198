.tab {
    background-color: $tab-default;
    color: $tab-text-color-default;
    border-radius: 10px;
    padding: 8px;

    &--active {
        background-color: $tab-active;
        color: $tab-text-color-active;
    }
}