.confirmation-coordinator-register-modal{
    height: 300px;
}
.coordinator-epa-container {
    .title {
        width: 313px;
    }

    .paragraph {
        &+.button {
            position: relative !important;
        }
    }

    .coordinator {
        path[fill="#4DFFDD"] {
            fill: $coordinator;
        }
    }

    .background-p {
        background-color: $blue-05;
        align-items: center;
        border-radius: 15px;
        z-index: 1;
        height: 87px;
        padding: 12px 23px;
        max-width: 294px;
        margin-bottom: 50px;
    }

    .table-results {
        margin-top: 10px;

        td,
        th {
            background-color: $blue-03;
            border-radius: 5px;
            padding: 11px;
            width: 26px;
        }

        th {
            font-weight: 700;
        }

        .total-title {
            width: 208px;
            padding: 18px 5px 15px 14px;
        }
    }

    .margin-top {
        margin-top: 200px;
    }

    .generation-map {
        display: flex;
        flex-direction: column;
        width: 345px;
        margin-top: 16px;
        gap: 12px;

        .generation {
            display: flex;
            flex-direction: row;
            flex-basis: 100%;
            gap: 12px;

            &.first {
                .info {
                    font-size: 16px;
                    background-color: $blue-03;

                    .role {
                        font-size: 12px;
                    }
                }
            }

            &.second {
                .info {
                    background-color: $blue-02;
                }
            }

            &.third {
                .column {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    gap: 12px;

                    .info {
                        border: none;
                    }
                }
            }

            &.title {
                width: 100%;
                align-items: center;
                justify-content: center;
                font-size: 12px;

                * {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;
                    
                    .icon {
                        color: $blue-01;
                        font-size: 13px;
                        align-self: center;

                        &.up {
                            rotate: 90deg;
                        }

                        &.down {
                            rotate: 270deg;
                        }
                    }
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: center;
                align-items: center;
                border: 1px solid;
                border-radius: 5px;
                padding: 8px;
                background-color: $blue-04;
                font-size: 12px;

                &.candidate {
                    border-color: $candidate;
                }

                &.host {
                    border-color: $host-02;
                }

                &.coordinator {
                    border-color: $coordinator;
                }

                &.director {
                    border-color: $director;
                }

                &.captain {
                    border-color: $captain;
                }

                .role {
                    color: rgba(#FFF, 0.7);

                    b {
                        color: #FFF;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }

        }

        & + .button {
            position: relative !important;
        }
    }
}

.epa-confirmation-modal {
    .modal-content {
        .buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 41px;
            width: 100%;

            .button {
                width: 116px;
                margin: 0;
            }

        }
    }
}
.coordinator-room-a-checkbox{
    top: 10px;
}
p.paragraph.p-room-p-0 {
    max-width: 346px !important;
    width: 346px !important;
    margin: 0;
    padding: 0;
}
p.paragraph.p-small-1 {
    max-width: 210px !important;
    width: 346px !important;
    margin: 0;
    padding: 0;
}
p.paragraph.p-room-p-1 {
    max-width: 338px !important;
    width: 338px !important;
    margin: 0;
    padding: 0;
}
.checkbox-room-p-1 {
    max-width: 302px !important;
    width: 302px !important;
    margin: 0;
    left: 20px;
}
.border-room-a-1{
    width: 300px;
    max-width: 300px;
    text-align: center;
    padding-left: 14px;
}
p.paragraph.p-room-p-2 {
    max-width: 338px !important;
    width: 335px !important;
    margin: 0;
    padding: 0;
}
p.paragraph.p-small-0 {
    max-width: 265px !important;
    width: 265px !important;
    margin: 0;
    padding: 0;
}
.margin-left{
    margin-left: 28px;    
}
.warning-card-0{
    bottom: 85px!important;
}
.warning-card-room-e-1 {
    display: flex;
    justify-content: center;
    /* margin-top: 68px; */
    z-index: 2;
    position: absolute;
    top: 159px;
}
.warning-card-box-room-e-1 {
    width: 346px;
    max-width: 346px;
    height: 100px;
    padding-top: 53px;
}
.warning-card-box{
    width: 346px;
    max-width: 346px;
}
.p-room-p-2{
    margin-left: 25px;
    width: 281px;
}

.avatar-modal-coordinator-room-p {
    position: relative;
    width: 324px;
    margin: 19px 12px 10px 11px;
    text-align: center;
}

.text-room-table{
    max-width: 293px;
    width: 293px;
    top: 49px;
    padding-left: 42px;
    position: absolute;
  }
  .warning-card-bottom{
    margin-top: 39px!important;
  }