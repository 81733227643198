.payment-method {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 343px;
    height: 93px;
    background-color: $blue-03;
    border-radius: 10px;
    z-index: 1;

    & * {
        pointer-events: none;
    }

    &--selected {
        border: 1px solid $blue-01;
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}