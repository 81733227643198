.checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    padding: 7px 43px;

    input {
        display: none;
    }

    &--checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 28px;
        width: 28px;
        background-color: transparent;
        border: 2px solid $blue-01;
        border-radius: 5px;

        &::after {
            font-family: 'acdc-font-icons', sans-serif;
            content: '\e802';
            font-size: 12px;
            color: $blue-01;
            position: absolute;
            display: none;
            text-align: center;
            top: 4px;
            left: 5px
        }
    }

    input:checked + &--checkmark::after {
        display: block;
        /* Mostrar el marcador cuando está marcado */
    }

    .background-label {
        display: block;
        position: relative;
        background-color: $blue-02;
        padding: 14px 14px;
        border-radius: 5px;
        width: 100%;
    }


    &.dynamic {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
        width: 323px;

        .checkbox--checkmark {
            position: relative;
        }

        .background-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            max-width: 283px;
            padding: 0;
            border-radius: 8px;
            gap: 1px;
            
            &>* {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 77px;
                height: 32px;
                padding: 10px 16px;
                border-right: 1px solid #0F1B2B;
                background-color: $blue-04;
                flex: 1;
                min-width: 55px;
                text-align: center;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    text-align: left;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    min-width: 79px;
                    padding-right: 15px;
                }
            }
        }
    }
}