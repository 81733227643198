.my-validators-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
        z-index: 1;
        margin-bottom: 20px;
    }

    .host-validators__stars {
        display: flex;
        position: relative;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 20px;

        .number-badge {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                position: absolute;
                color: $candidate
            }
        }
    }
}
