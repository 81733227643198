.select {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 343px;
    height: 48px;
    border-radius: 15px;
    background-color: $blue-04;
    color: rgba(#FFF, 0.5);
    border: none;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;

    
}