.step {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    
    .button {
        position: relative;
        margin-top: 53px;
        bottom: 40px;
    }
}