.director-option-container {
    display: flex;
    width: 381px;
    margin-top: 10px;
    align-items: flex-start;
    justify-content: space-evenly;
}

.director-symbol {
    background-color: #0F1B2B;
    width: 51px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.director-text-options{
    display: flex;
    /* justify-content: space-evenly; */
    width: 300px
}