$circle-size: 150px;

.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    height: 100vh;
}

.circle {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;





}



main {
    display: flex;
    justify-content: center;
    align-items: center;
 

    .icon-container {
        display: flex;
        justify-content: space-around;
        gap: 20px;

        .icon {
            font-size: 3em;
            padding: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            color: white;

            &.red {
                background-color: red;
            }

            &.green {
                background-color: green;
            }

            &.blue {
                background-color: blue;
            }
        }
    }
}

.text-candidate{
    color: #36FFDB;
    border: solid 1px #36FFDB;
    border-radius: 6px;
   
    padding: 3px;
    margin: 10px;
}
.container-card-host{
    display: flex;
    justify-content: space-between;
    width: 312px;

}
.back-img-host{
    height: 36px;
    display: flex;
    margin-top: 18px;
}