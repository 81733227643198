.image-button {
    display: flex;
    width: 40px;
    height: 40px;
    border: 1px solid #FFF;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
        pointer-events: none;
        width: 26px;
        height: 26px;
    }

    &.gradient {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 164px;
        height: 164px;
        border: none;
        overflow: hidden;
        border-radius: 15px;
        text-align: center;

        .circle {
            position: absolute;
            width: 884px;
            height: 617px;
            background-color: $blue-03;
            border-radius: 50%;
            top: -514px;
            left: -540px;
            z-index: 1;
            pointer-events: none;

            &.secondary {
                background: $background-circle-secondary;
                opacity: 0.5;
            }
        }

        img {
            width: 105px;
            height: 105px;
            z-index: 2;
            pointer-events: none;
        }
    }
}