.host-filter-day-container {

    .title {
        font-weight: 700;
    }

    .paragraph {
        &+.button {
            position: relative !important;
        }

        &.like-card {
            background-color: $blue-03;
            border-radius: 10px;
            padding: 11px 6px;
            margin: 8px 0;

            &--secondary {
                background-color: $blue-04;
            }
        }
    }

    .referred-table {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        margin-bottom: 20px;
        gap: 10px;

        &__header {
            display: flex;
            flex-direction: row;
        }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            &__column {

                .index {
                    font-size: 22px;
                    font-weight: 700;
                    display: block;
                    width: 10px;
                    text-align: center;
                }

                .blue {
                    background-color: $blue-03;
                    border-radius: 5px;
                    padding: 11px 8px;

                    img {
                        visibility: hidden;
                    }

                    &.name {
                        display: flex;
                        width: 79px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.h1,
                    &.h2,
                    &.h3 {
                        padding: 11px 9px;
                        width: calc(100% - 12px);
                    }

                    &.time {
                        display: block;
                        width: 60px;
                        text-align: center;
                    }

                    &.active {
                        border: 1px solid $blue-01;
                        padding: 11px 8px;

                        img {
                            visibility: visible;
                        }
                    }
                }

                .time.blue {
                    background-color: $blue-01;
                    color: rgba(#FFF, 0.7);

                    span {
                        color: #FFF;
                    }
                }
            }
        }
    }

    .segment {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;

        .info-card {
            display: flex;
            width: 253px;
            min-height: 98px;
            background-color: $blue-03;
            border-radius: 5px;
            padding: 6px;

            .icon-info {
                display: flex;
                align-self: flex-end;
                font-size: 32px;
                background-color: $blue-01;
                width: 32px;
                text-align: center;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }

        .time-box {
            display: flex;
            width: 74px;
            height: 97px;
            flex-direction: column;
            background-color: $blue-03;
            border-radius: 5px;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 6px;
            color: rgba(#FFF, 0.7);

            span {
                color: #FFF;
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

    .segment-total {
        display: flex;
        width: 100%;
        max-width: calc(375px - 16px);
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
        gap: 10px;

        .total {
            display: flex;
            background-color: $blue-03;
            width: 74px;
            height: 97px;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            padding: 6px;
        }

        .time-box {
            display: flex;
            width: 74px;
            height: 97px;
            flex-direction: row;
            background-color: $blue-01;
            border-radius: 5px;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 6px;
            color: rgba(#FFF, 0.7);
            gap: 5px;

            span {
                color: #FFF;
                font-size: 18px;
                font-weight: 700;
            }
        }

        & + .button {
            position: relative !important;
        }
    }

    .card {
        padding-left: 28px;
        padding-right: 28px;
        margin-bottom: 20px;
    }

    .checkbox-table {
        display: flex;
        flex-direction: column;
        max-width: 343px;
        gap: 21px;
        margin-bottom: 40px;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 21px;

            &.margin-to-button {
                margin-bottom: 100px;
            }

            .checkbox {
                padding: 0 21px 30px;
            }

            .checkbox-table__item__start-time {
                display: flex;
                background-color: $blue-03;
                border-radius: 10px;
                width: 135px;
                height: 40px;
                align-items: center;
                justify-content: center;
            }

            .checkbox-table__item__end-time {
                display: flex;
                background-color: $blue-03;
                border-radius: 10px;
                width: 135px;
                height: 40px;
                align-items: center;
                justify-content: center;
            }

            .checkbox-table__item__name {
                display: flex;
                width: 286px;
                height: 38px;
                background-color: $blue-03;
                border-radius: 10px;
                align-items: center;
                justify-content: flex-start;
                padding-left: 11px;
            }
        }

        & + .button {
            position: relative !important;
        }
    }

    .screen-my-performance {
        position: relative;
        z-index: 1;
        pointer-events: none;
        margin-bottom: 20px;

        .cursor-hand {
            position: absolute;
            right: 30px;
            bottom: -20px;
            z-index: 2;
        }
    }

    .screen-bridge {
        margin-bottom: 40px;

        & + .button {
            position: relative !important;
        }
    }

    .aspirants-pdl {
        margin-bottom: 40px;

        & + .button {
            position: relative !important;
        }
    }

    &-card {
        margin-bottom: 40px !important;
    }

    &-button {
        position: relative !important;
    }
}