.challenge-page {
    overflow: hidden;

    .title {
        margin-bottom: 0 !important;
    }

    .subtitle {
        margin: 0 0 10px;
        font-size: 18px;
        opacity: 0.5;
    }

    header {
        max-width: 375px !important; 

        .icon {
            &.left {
                left: 42px !important;
            }
        }
    }

    .drag-item__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 7px 0;
        border-top: 1px solid $blue-01;
        border-bottom: 1px solid $blue-01;
        z-index: 1;

        .drag-item__info {
            display: flex;
            position: relative;
            flex-direction: row;
            align-items: center;
            background-color: $blue-03;
            border-radius: 5px;
            padding: 7px 12px;
            width: 306px;
            height: 50px;
            z-index: 1;

            .paragraph {
                margin: 0;
            }

            .icon-container {
                display: flex;
                position: absolute;
                flex-direction: column;
                justify-self: flex-end;
                align-items: center;
                justify-content: center;
                right: 5px;

                .icon {
                    color: $blue-01;
                    width: 30px;

                    &.up {
                        rotate: 90deg;
                    }

                    &.down {
                        rotate: 270deg;
                    }
                }
            }
        }
    }

    .day-rules-info {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 412px;
        z-index: 1;
        margin-top: 20px;


        .greet {
            margin-bottom: 28px;
        }


        .card {
            padding: 0 12px;
            margin-top: 31px;
            margin-bottom: 216px;
        }

        .button {
            position: relative;
        }
    }

    .paragraph {
        max-width: 313px;
    }

    .step {
        z-index: 1;

        .card {
            text-align: center;
            margin-top: 10px;
            padding-top: 20px;

            .title-card-read {
                text-align: center;
                font-weight: 700;
                margin-top: 30px;
            }

            .card-read {
                margin: 5px 10px 34px;
                padding: 0 10px;
                overflow-y: scroll;
                height: 100%;
                height: 360px;
                max-height: 360px;
            }
        }

        .phrase-container {
            display: flex;
            position: relative;
            width: 343px;
            height: 79px;
            border-radius: 39.5px;
            background-color: $blue-03;
            margin-top: 40px;
            margin-bottom: 40px;

            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 20px solid transparent;
                border-bottom: 20px solid $blue-03;
                border-right: 20px solid transparent;
                border-left: 20px solid transparent;
                right: 30px;
                top: -37px;
            }

            .book {
                position: relative;
                width: 108px;
                height: 108px;
                top: -40px;
            }

            .paragraph {
                position: relative;
                top: -10px;
                left: -20px;
            }
        }

        .text-area {
            margin: 5px 0 20px;
            z-index: 1;
        }

        .counter {
            position: relative;
            margin-top: 150px;
        }

        .button {
            position: absolute;
            bottom: 40px;
        }
    }

    #step-1 {

        .card {
            margin-top: 10px;
            margin-bottom: 123px;
        }

        .text-area {
            margin: 5px 0 20px;
        }

        .button {
            position: absolute;
            bottom: 40px;
        }
    }

    #step-2 {
        .button {
            position: absolute;
            bottom: 40px;
        }
    }

    #step-3 {
        .screen-my-performance {
            position: relative;
            z-index: 1;
            pointer-events: none;
            margin-bottom: 20px;

            .cursor-hand {
                position: absolute;
                right: 84px;
                bottom: -20px;
                z-index: 2;
            }
        }

        .paragraph {
            .role-color {
                color: $candidate;
            }
        }

        .screen-bridge {
            margin-bottom: 150px;
        }
    }

    #step-4 {

        .screen-selected-meta {

            &+.paragraph {
                margin-bottom: 190px;
            }
        }

        .paragraph {
            margin: 5px;

            .role-color {
                color: $candidate;
            }
        }

        .good-hand {
            margin-bottom: 54px;

            &+.paragraph {
                margin-bottom: 190px;
            }
        }

        .button {
            position: absolute;
            bottom: 40px;
        }

    }

    #step-5 {
        .good-hand {
            margin-bottom: 54px;

            &+.paragraph {
                margin-bottom: 190px;
            }
        }
    }

    #step-7 {
        .epa-schedule-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 375px;
            padding-bottom: 24px;
            margin-top: 20px;

            &__key {
                width: 215px;
                background-color: $blue-03;
                border-radius: 20px;
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                padding: 9px 0;
                margin-bottom: 21px;
            }

            .paragraph {
                margin-bottom: 7.5px;
                font-size: 16px;

                b {
                    font-weight: 600;
                }

                &--xs {
                    font-size: 14px;
                    margin-top: 10px;
                }

                .icon-info {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $blue-01;
                    font-size: 17px;
                    opacity: 1;
                    z-index: 1;
                }
            }
        }
    }
}