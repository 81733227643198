.copy-field {
    display: flex;
    border: 2px solid $blue-01;
    border-radius: 15px;
    color: $blue-01;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    align-items: center;
    padding: 0 13px;
    box-shadow: 0px 0px 5px 1px rgba(0, 99, 221, 0.50);

    input {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    button {
        background: transparent;
        border: none;
        color: $blue-01;
        font-size: 16px;
        font-weight: 400;
    }
}

.password-copied {
    position: relative;
    margin-top: 15px;
    width: 150px;
    background-color: green;
    border-radius: 5px;
    opacity: 0.7;
    transition: display 2s ease-in-out;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent green transparent;
    }
}