.info-section-bridge {
    position: relative;
    width: 311px;
    text-align: center;
    margin-bottom: 42px;

    h2, h3 {
        margin-bottom: 35px;
    }

    .button {
        margin-top: 44px;
    }
}