.text-area {
    position: relative;
    width: 100%;
    max-width: 347px;
    height: 94px;
    background-color: $blue-02;
    border-radius: 5px;
    border: none;
    padding: 0 7px;
    z-index: 1;
    color: #FFF;
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    font-weight: 400;
    resize: none;


    &::placeholder {
        opacity: 0.9;
    }

    &:focus {
        background-color: $blue-03;
        outline: 3px solid $blue-01;

        &~.text-field--icon {
            color: $blue-01;
            background-color: $blue-03;
        }
    }

    &:invalid:not(:placeholder-shown) {
        background-color: $blue-03;
        outline: 3px solid $error;
        opacity: 0.9;

        &::placeholder {
            display: none
        }

        &+.error {
            display: block;
            color: red;
            margin-top: 5px;
            text-align: left;
            transition: 0.5s ease-in-out;
            font-size: 14px;
        }
    }

    &:disabled {
        color: rgba(#FFF, 0.9);

        &~.text-field--icon {
            color: rgba(#FFF, 0.5);
        }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s;
        -webkit-text-fill-color: #fff !important;
    }
}