.radio-button {
    width: 38px;
    height: 38px;
    z-index: 2;

    input[type="radio"] {
        display: none;
        &:checked {
            &~.radio-button__checkmark::before {
                content: attr(data-label);
                /* Mostrar el número */
                color: #fff;
                font-size: 14px;
            }

            &~.radio-button__checkmark {
                background-color: $blue-01;
                border-color: $blue-01;
            }
        }

        &~.radio-button__checkmark {
            background-color: $blue-03;
            border-color: $blue-03;
        }

        
    }

    &__checkmark {
        position: relative;
        width: 38px;
        height: 38px;
        border: 1px solid $blue-03;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}