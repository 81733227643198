.epa-schedule-page {
    height: 100%;

    header {
        display: flex;
        position: relative;
        background-color: $blue-02;
        flex-direction: column;
        z-index: 1;
        margin: 0;
        padding: 73px 37px 28px;

        .title {
            margin-bottom: 0;
        }

        .subtitle {
            font-size: 16px;
            font-weight: 400;
            opacity: 0.7;
            margin: 0;
        }
    }

    .epa-schedule-content {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-top: 10px;
        align-items: center;
        background-color: $blue-05;
        padding: 10px 14px;
        z-index: 1;

        .epa-schedule-info {
            display: flex;
            position: relative;
            font-size: 16px;
            gap: 16px;

            .title {
                font-weight: 400;
                margin: 0;
            }

            .text {
                font-weight: 700;
            }
        }

        .paragraph {
            max-width: 375px;
            margin-top: 30px;
        }

        .epa-schedule-form {
            form {
                align-items: center;
            }
        }

    }

    .epa-confirmation-screen {
        text-align: center;
        padding: 20px 0 40px;

        .title {
            font-weight: 700;
        }

        .paragraph {
            margin-top: 10px;
        }

        .image {
            background-image: $light;
            background-position: center;
            background-repeat: no-repeat;
        }

        .epa-schedule-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 343px;
            padding-bottom: 24px;
            margin-top: 20px;

            &__key {
                width: 313px;
                background-color: $blue-03;
                border-radius: 20px;
                text-align: center;
                font-size: 17px;
                font-weight: 700;
                padding: 9px 0;
                margin-bottom: 21px;
            }

            .paragraph {

                b {
                    font-weight: 600;
                }

                .icon-info {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $blue-01;
                    font-size: 17px;
                    opacity: 1;
                    z-index: 1;
                }
            }
        }
    }

    .modal-container {
        overflow: scroll;

        .confirmation-modal {
            position: relative;
            display: flex;
            height: 100%;
            max-height: 244px;
            align-items: center;
            justify-content: center;

            &.step {
                max-height: 376px;
                padding-top: 37px;

                .title {
                    font-weight: 700;
                }

                .paragraph {
                    margin-top: 10px;
                }

                .image {
                    background-image: $light;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            .modal-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: inherit;
            }
        }
    }

    .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            margin-bottom: 0;
        }

        .subtitle {
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 47px;
        }

        .paragraph {
            margin-bottom: 96px;
        }
        
        .image {
            margin-top: -77px;
            margin-bottom: 40px;
        }

        .button {
            margin-bottom: 24px;
        }

        .container-card {
            display: flex;
            width: 295px;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            border: 2px solid $blue-04;
            padding: 35.63px 0;
            margin-bottom: 89px;
            margin-top: -77px;
            background-color: $blue-02;

            .epa-schedule-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 236px;
                padding-bottom: 24px;
                margin-top: 20px;

                &__key {
                    width: 215px;
                    background-color: $blue-03;
                    border-radius: 20px;
                    text-align: center;
                    font-size: 11px;
                    font-weight: 700;
                    padding: 9px 0;
                    margin-bottom: 21px;
                }

                .paragraph {
                    margin-bottom: 7.5px;
                    font-size: 11px;

                    b {
                        font-weight: 600;
                    }

                    &--xs {
                        font-size: 9.6px
                    }

                    .icon-info {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: $blue-01;
                        font-size: 17px;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }
    }
}