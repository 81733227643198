.epa-day-modal {
    height: 425px !important;
    max-height: 405px !important;

    .modal-content {
        .button {
            position: relative !important;
        }
    }
}

.host-alignment-day-container {
    .tutorial-time-management {
        & + .button {
            position: relative !important;
        }
    }
}


.epa-day-container {
    &.epa-list-candiate{
        max-width: 329px;
        margin: 8px;
    }


    .paragraph {
        &.custom-border-color {
            border: 1px solid $candidate;
            border-radius: 10px;
        }
        &.custom-border-color-room-a{
            max-width: 413px;
            width: 340px;
            padding-top: 5px;
            margin: 10px;
            height: 50px;
            border: solid 1px white;
            border-radius:   6px;
            font-family: Arial, Helvetica, sans-serif;
            
         }  
         &.custom-border-color-room-a >.info, .name{
            font-weight: bold;
            font-size: 16px;
            color: white;
         }
         &.custom-border-color-room-a >.info, .role{
            font-weight: 100;
            font-size: 12px;
            color: white;
         }
         
    }

    .score-board {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: $blue-05;
        border-radius: 15px;
        width: 343px;
        height: 188px;
        align-items: center;
        justify-content: center;

        .title {
            width: 100%;
        }

        &__player {
            width: 133px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .text-field {
                width: 70px;
                padding-top: 2px;

                input {
                    padding: 0;
                    text-align: center;
                }
            }
        }

    }

    .table {
        width: 353px;
        text-align: center;
        border-spacing: 10px;

        th {
            background-color: $blue-03;
            border-radius: 10px;
            word-break: break-all;
            min-width: 51px;
            padding: 5px;
        }

        td {
            background-color: $blue-04;
            border-radius: 10px;
            padding: 5px;
            font-size: 12px;

            &.border {
                border: 1px solid $candidate;
            
            }
        }
    }

    .conclutions {
        width: 311px;
        background-color: $blue-03;
        border-radius: 15px;
        padding: 22px 16px;

        ol {
            padding: 0 16px;

            li {
                margin-bottom: 10px;
            }
        }
    }

    .group-radio[name=traditional] {
        flex-direction: column;
        padding: 0 16px;

        .radio-button {
            position: relative;
            width: 290px;

            .auxiliar-text {
                position: absolute;
                font-size: 16px;
                font-weight: 700;
                top: 10px;
                left: 48px;
            }
        }
    }

    .radio-question {
        margin-top: 16px;
        z-index: 1;

        &--content {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: center;
            gap: 16px;
            margin-bottom: 16px;

            .paragraph {
                &.custom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 140px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background-color: $blue-04;
                    font-size: 14px;
                }
            }
            
            .traditional {
                width: 164px;
                border-radius: 10px;
                margin-top: 16px;

                .paragraph {
                    margin: 0;

                    &.old {
                        background-color: $blue-03;
                        padding: 6px 0px;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                        color: rgba(#FFF, 0.5);
                    }
                }
            }

            .acdc {
                width: 164px;

                .paragraph {
                    margin: 0;

                    &.new {
                        background-color: $blue-03;
                        padding: 6px 0px;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                    }
                }
            }
        }
    }

    .checkbox {
        width: 253px;
    }

    .button {
        position: relative !important;

        &#wait-list, &#finish {
            margin-top: 20px;
        }
    }
}

.game-over-confirm {
    height: 286px;

    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;

        .title{
            b {
                color: $noSent;
                font-weight: 700;
            }
        }

        .paragraph {
            b {
                color: #2E9BFF;
                font-weight: 700;
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 41px;

            .button {
                width: 116px;
                height: 40px;
            }
        }
    }
}