@mixin button-role($role) {
    @if $role =='candidate' {
        background-color: $candidate;
        box-shadow: 0px 0px 10px 0px rgba($candidate, 0.3);
        color: $black;
    }

    @else if $role =='host' {
        background-color: $host-02;
        box-shadow: 0px 0px 10px 0px rgba($host-02, 0.3);
        color: $black;
    }

    @else if $role =='coordinator' {
        background-color: $coordinator;
        box-shadow: 0px 0px 10px 0px rgba($coordinator, 0.3);
    }

    @else if $role =='director' {
        background-color: $director;
        box-shadow: 0px 0px 10px 0px rgba($director, 0.3);
        color: $black;
    }
}