.paragraph {
    padding: 0 5px;

    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--justify {
        text-align: justify;
    }

    &--xs {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.7;
    }

    &--s {
        font-size: 16px;
        font-weight: 400;
    }

    &--16 {
        padding: 0 16px;
    }

    &--green {
        color: $green;
    }

    &--with-background {
        border-radius: 15px;
        padding-top: 16px;
        padding-bottom: 16px;

        &.primary {
            background-color: $blue-04;
        }

        &.secondary {
            background-color: $blue-05;
        }
    }
}