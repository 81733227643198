.user-card-info {
    position: relative;
    width: 294px;
    padding: 11px 13px;
    margin-bottom: 0px !important;

    &.candidate {
        &.active {
            border: 1px solid $candidate;
        }
    }

    &.host {
        &.active {
            border: 1px solid $host-02;
        }
    }

    &.coordinator {
        &.active {
            border: 1px solid $coordinator;
        }
    }

    &.director {
        &.active {
            border: 1px solid $director;
        }
    }

    &.captain {
        &.active {
            border: 1px solid $captain;
        }
    }

    .icon-state-selected {
        position: absolute;
        font-size: 12px;
        top: 11px;
        right: 14px;
        color: $success;
    }

    .menu-user-info {
        &-title {
            .heading {
                text-align: left;
            }
        }

        .training-content {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__item {
                font-size: 22px;
                color: $candidate;
                opacity: 0.3;
                margin: 0;

                &::before {
                    margin: 0;
                }

                &.active {
                    opacity: 1;
                }
            }
        }
    }
}