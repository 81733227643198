.challenge-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;

    &--icon {
        width: 136px;
        height: 148px;
        background-position-x: 12px;
        margin-right: 10px;

        &--mic {
            background-image: $mic-image-136-148;
            background-repeat: no-repeat;
        }

        &--list {
            background-image: $list-box-136-148;
            background-repeat: no-repeat;
        }

        &--calendar {
            background-image: $calendar-136-148;
            background-repeat: no-repeat;
        }

        &--sync {
            background-image: $sync-136-148;
            background-repeat: no-repeat;
        }

        &--victory-hand {
            background-image: $victory-hand-136-148;
            background-repeat: no-repeat;
        }

        &--rocket-icon {
            background-image: $rocket-icon-136-148;
            background-repeat: no-repeat;
            width: 136px;
            margin-right: 5px;
        }   

        &--host-money-hand {
            background-image: $host-money-hand-136-148;
            width: 136px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-top: 30px;
        }

        &--host-screen-tools {
            background-image: $host-screen-tools-136-148;
            width: 136px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-top: 30px;
        }

        &--host-mail {
            background-image: $host-mail-136-148;
            width: 136px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-top: 30px;
        }

        &--host-key-hand {
            background-image: $host-key-hand-136-148;
            width: 136px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-top: 30px;
        }

        &--good-hand {
            background-image: $good-hand;
            background-repeat: no-repeat;
            width: 136px;
            background-size: contain;
        }

        &--check {
            background-image: $check-icon-136-148;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &--coordinator-calendar {
            background-image: $coordinator-calendar-136-148;
            background-repeat: no-repeat;
            background-size: contain;
            width: 136px;
        }


        &--coordinator-sync {
            background-image: $coordinator-sync-136-148;
            background-repeat: no-repeat;
            background-size: contain;
            width: 136px;
        }

        &--coordinator-hand-key {
            background-image: $coordinator-hand-key-136-148;
            background-repeat: no-repeat;
            background-size: contain;
            width: 136px;
        }

        &--book {
            background-image: $book;
            background-repeat: no-repeat;
            background-size: contain;
            width: 136px;
        }

        &--coordinator-screen-tools {
            background-image: $coordinator-screen-tools;
            background-repeat: no-repeat;
            background-size: contain;
            width: 136px;
        }


        &--touch-screen-coordinator {
            background-image: $touch-screen-coordinator;
            background-repeat: no-repeat;
            background-size: contain;
            width: 136px;
        }


        .light {
            position: relative;
            width: 100%;
            height: 100%;
            background: $light;
            background-size: 100%;
            background-position: 3px center;
            z-index: -1;
        }
    }

    .modal-content {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 32px;
        }

        .card {
            margin-bottom: 6px;
        }
    }
}