.list-modal {
    position: relative;
    height: 451px;

    .circle {
        background-color: none;
        width: 0 !important;
        height: 0 !important;
    }

    .modal-background {
        background: none;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: inherit;

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 32px;
            height: 32px;
            font-size: 32px;
        }

        .title {
            position: relative;
            margin-top: 75px;
        }

        .list-modal--list {
            list-style: none;
            padding: 0;
            margin-top: 25px;
            flex: 1;

            &--item {
                border-top: 1px solid rgba($blue-01, 0.3);
                padding: 10px 8px 7px 24px;

                &:last-child {
                    border-bottom: 1px solid rgba($blue-01, 0.3);
                }
            }
        }

        .carousel-controls {
            display: flex;
            position: relative;
            width: 100%;
            flex-direction: row;
            color: $blue-01;
            font-size: 32px;
            justify-content: space-around;
            align-items: center;
            align-self: flex-end;
            bottom: 28px;

            .carousel-control {

                &--pagination {
                    display: flex;
                    flex-direction: row;
                    list-style: none;
                    gap: 10px;
                    margin: 0;
                    padding: 0;

                    .item {
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        background-color: $blue-03;

                        &.active {
                            background-color: $blue-01;
                        }
                    }
                }

                &--prev,
                &--next {
                    &.disabled {
                        color: $blue-03;
                    }
                }
            }
        }
    }
}