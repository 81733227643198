.switch {
    display: flex;
    flex-direction: row;
    gap: 9px;

    #switch {
        display: none;
    }

    .label-switch {
        display: flex;
        width: 30px;
        height: 29px;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        border-radius: 5px;
        color: #000;

        &.active {
            color: #FFF;
            border: none;

            &.success {
                background-color: #00a650;
            }

            &.error {
                background-color: #d32f2f;
            }
        }
    }
}

.toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+.slider {
            background-color: $blue-02;

            &:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            &.round {

                &:before {
                    background-color: #2FE984;
                }
            }
        }

        &:focus+.slider {
            box-shadow: 0 0 1px $blue-02;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blue-02;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
                background-color: #B6B6B6;
            }
        }
    }
}