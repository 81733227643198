.profile-info {
    display: flex;
    position: relative;
    flex-direction: column;

    &__name {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
    }
}