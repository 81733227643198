.paragraph,
.validations {
    .candidate {
        color: $candidate;
    }
}

.validations {
    position: relative;
    display: flex;
    width: 100%;
    height: 35px;
    border: 1px solid $candidate;
    border-radius: 15px;
    padding: 7px auto;
    max-width: 343px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    &.disabled {
        background-color: $blue-02;
        border: 1px solid $blue-04;
        opacity: 0.7;
    }
}

.warning-card {
    position: relative;
    background-color: $blue-05;
    border-radius: 15px;
    margin-top: 70px;
    padding-top: 20px;
    margin-bottom: 150px;

    img {
        position: absolute;
        bottom: 77px;
        left: calc(50% - 46px);
    }
}

.sync-meet-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $blue-02;
    border-radius: 20px;
    padding: 60px 15px 20px;
    margin-top: 130px;
    margin-bottom: 200px;

    img {
        position: absolute;
        top: -130px
    }

    .title {
        font-weight: 700;
    }

    .meet-link {
        display: flex;
        background-color: $blue-03;
        min-width: 313px;
        height: 38px;
        text-align: center;
        border-radius: 15px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }


}

.bottom-advice {
    position: absolute;
    bottom: 86px;
}

.termsandconditions {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 16px;
    max-width: 313px;
    margin-bottom: 150px;
}

#step-2, #step-3 {
    #read-button {
        position: relative;
    }

    .bottom-advice {
        position: absolute;
        bottom: 86px;
    }
}

.challenge-modal {
    .title {
        margin-top: 70px;
        font-weight: 700;
    }
}