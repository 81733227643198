.tabs {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    max-width: 375px;
    height: 37px;
    justify-content: center;
    gap: 5px;
    z-index: 2;
}