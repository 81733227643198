.login-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    color: #FFF;
    z-index: 2;
    margin-top: 23px;

    &--forgot-password {
        color: $blue-link;
        text-decoration: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 3px;
        align-self: center;
    }

    &--title {
        align-self: center;
        margin: 0 0 35px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 44px;
        display: flex;
        align-items: center;
    }
}