$circle-size: 150px;

.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    height: 100vh;
}

.circle {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;





}
.director-card-asyn{
    display: flex;
    gap: 10px;
}
.card-text-asyn{
    display: flex;
    justify-content: space-around;
    width: 335px;
    max-width: 340px;
    gap: 10px;
}
.card-text-asyn{
    width: 172px;
    height: 52px;
    background: #202d47;
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-around;
    padding-left: 10px;

}
.card-asyn-one{
    height: 52px;
    background: #202d47;
    border-radius: 5px;
    width: 69px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.card-asyn-two{
    height: 52px;
    background: #202d47;
    border-radius: 5px;
    width: 69px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
