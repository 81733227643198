.menu-user-info {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    z-index: 2;


    &-avatar {
        width: 105px;
        height: 105px;
        border-radius: 50%;
        border: 5px solid $background-bar-color;
        background-image: $avatar-candidate;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 10px;
        padding: 0;

        &.small {
            width: 65px;
            height: 65px;

            svg {
                width: 86px;
                height: 84px;
            }
        }

        svg {
            margin: -10px;
        }

    }

    &-title {
        font-size: 17px;
        font-weight: 700;

        &>* {
            margin: 0;
        }

        span {
            font-weight: 600;
        }

        .heading {
            max-width: 150px;
            white-space: wrap;

            &.left {
                text-align: left;
            }

            &.right {
                text-align: right;
            }

            &.center {
                text-align: center;
            }

            &.justify {
                text-align: justify;
            }
        }

    }

    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
        text-align: left;
        gap: 8px;
    }

    &--candidate {
        .menu-user-info-title {
            span {
                color: $candidate;
            }
        }

        .menu-user-info-avatar {

            &.progress-1-8 {
                svg {
                    mix-blend-mode: color-dodge;

                    path[id='1'] {
                        fill: $candidate;
                    }

                    path:not([id='1']) {
                        fill: $background-bar-color;
                    }
                }

            }

            &.progress-2-8 {
                svg {

                    path[id='1'],
                    path[id='2'] {
                        fill: $candidate;
                    }

                    path:not([id='1']):not([id='2']) {
                        fill: $background-bar-color;
                    }

                }
            }

            &.progress-3-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'] {
                        fill: $candidate;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-4-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'] {
                        fill: $candidate;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-5-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'] {
                        fill: $candidate;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-6-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'] {
                        fill: $candidate;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-7-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'] {
                        fill: $candidate;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']):not([id='7']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-8-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'],
                    path[id='8'] {
                        fill: $candidate;
                    }
                }
            }
        }
    }

    &--host {
        .menu-user-info-title {
            span {
                color: $host-02;
            }
        }

        .menu-user-info-avatar {
            &.progress-1-8 {
                svg {
                    path[id='1'] {
                        fill: $host-02;
                    }

                    path:not([id='1']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-2-8 {
                svg {

                    path[id='1'],
                    path[id='2'] {
                        fill: $host-02;
                    }

                    path:not([id='1']):not([id='2']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-3-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'] {
                        fill: $host-02;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-4-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'] {
                        fill: $host-02;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-5-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'] {
                        fill: $host-02;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-6-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'] {
                        fill: $host-02;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-7-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'] {
                        fill: $host-02;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']):not([id='7']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-8-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'],
                    path[id='8'] {
                        fill: $host-02;
                    }
                }
            }
        }
    }

    &--coordinator {
        .menu-user-info-title {
            span {
                color: $coordinator;
            }
        }

        .menu-user-info-avatar {
            &.progress-1-8 {
                svg {
                    path[id='1'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-2-8 {
                svg {

                    path[id='1'],
                    path[id='2'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']):not([id='2']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-3-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-4-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-5-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-6-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-7-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'] {
                        fill: $coordinator;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']):not([id='7']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-8-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'],
                    path[id='8'] {
                        fill: $coordinator;
                    }
                }
            }
        }
    }

    &--director {
        .menu-user-info-title {
            span {
                color: $director;
            }
        }

        .menu-user-info-avatar {
            &.progress-1-8 {
                svg {
                    path[id='1'] {
                        fill: $director;
                    }

                    path:not([id='1']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-2-8 {
                svg {

                    path[id='1'],
                    path[id='2'] {
                        fill: $director;
                    }

                    path:not([id='1']):not([id='2']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-3-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'] {
                        fill: $director;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-4-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'] {
                        fill: $director;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-5-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'] {
                        fill: $director;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-6-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'] {
                        fill: $director;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-7-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'] {
                        fill: $director;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']):not([id='7']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-8-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'],
                    path[id='8'] {
                        fill: $director;
                    }
                }
            }
        }
    }

    &--captain {
        .menu-user-info-title {
            span {
                color: $captain;
            }
        }

        .menu-user-info-avatar {
            &.progress-1-8 {
                svg {
                    path[id='1'] {
                        fill: $captain;
                    }

                    path:not([id='1']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-2-8 {
                svg {

                    path[id='1'],
                    path[id='2'] {
                        fill: $captain;
                    }

                    path:not([id='1']):not([id='2']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-3-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'] {
                        fill: $captain;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-4-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'] {
                        fill: $captain;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-5-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'] {
                        fill: $captain;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-6-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'] {
                        fill: $captain;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-7-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'] {
                        fill: $captain;
                    }

                    path:not([id='1']):not([id='2']):not([id='3']):not([id='4']):not([id='5']):not([id='6']):not([id='7']) {
                        fill: $background-bar-color;
                    }
                }
            }

            &.progress-8-8 {
                svg {

                    path[id='1'],
                    path[id='2'],
                    path[id='3'],
                    path[id='4'],
                    path[id='5'],
                    path[id='6'],
                    path[id='7'],
                    path[id='8'] {
                        fill: $captain;
                    }
                }
            }
        }
    }
}