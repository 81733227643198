.icon-item {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 115px;
    z-index: 1;

    &__icon {
        width: 70px;
        height: 70px;
        position: relative;
        z-index: 1;
    }

    &__text {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 144px;
        height: 70px;
        padding: 37px 10px 12px;
        text-align: center;
        background-color: $blue-03;
        border-radius: 15px;
        top: -30px;
        font-family: Urbanist, sans-serif;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}