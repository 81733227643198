.portal-bridge {
    position: relative;
    width: 375px;
    height: 154px;
    overflow: hidden;
    padding-top: 20px;

    #portal-selector {
        position: absolute;
        bottom: 44px;
        transition: left 0.5s ease;
        left: 122px;
        z-index: 1;

        /*&.position-first {
            left: -5px
        }

        &.position-second {
            left: 122px
        }

        &.position-third {
            left: 245px
        }*/
    }

    .portal-bridge-icon {
        position: relative;
        left: 10px;
        transition: left 0.5s ease;
        z-index: 1;
    }

    &--candidate {
        svg {

            path[fill="#919BAF"]:not([d*="M1054.88"]),
            path[fill="white"],
            path[fill="#D9EBFF"] {
                fill: $candidate;
            }
        }

        #portal-selector {
            path[stroke="#4DFFDD"], rect  {
                stroke: $candidate;
            }
        }
    }

    &--host {
        svg {

            path[fill="#919BAF"]:not([d*="M1054.88"]),
            path[fill="white"],
            path[fill="#D9EBFF"] {
                fill: $host-02;
            }
        }

        #portal-selector {
            path[stroke="#4DFFDD"], rect[stroke="#4DFFDD"] {
                stroke: $host-02;
            }
        }

    }

    &--coordinator {
        svg {

            path[fill="#919BAF"]:not([d*="M1054.88"]),
            path[fill="white"],
            path[fill="#D9EBFF"] {
                fill: $coordinator;
            }
        }

        #portal-selector {
            path[stroke="#4DFFDD"], rect[stroke="#4DFFDD"] {
                stroke: $coordinator;
            }
        }
    }

    &--director {
        svg {

            path[fill="#919BAF"]:not([d*="M1054.88"]),
            path[fill="white"],
            path[fill="#D9EBFF"] {
                fill: $director;
            }
        }

        #portal-selector {
            path[stroke="#4DFFDD"], rect[stroke="#4DFFDD"] {
                stroke: $director;
            }
        }
    }

    &--captain {
        svg {

            path[fill="#919BAF"]:not([d*="M1054.88"]),
            path[fill="white"],
            path[fill="#D9EBFF"] {
                fill: $captain;
            }
        }

        #portal-selector {
            path[stroke="#4DFFDD"], rect[stroke="#4DFFDD"] {
                stroke: $captain;
            }
        }
    }

    &-dates {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        font-weight: 400;

        .first {
            position: absolute;
            width: auto;
            max-width: 90px;
            text-align: center;
            left: 0px;
            transition: left 0.5s ease;
        }

        .second {
            position: absolute;
            width: auto;
            max-width: 80px;
            text-align: center;
            left: 110px;
            transition: left 0.5s ease;
        }

        .third {
            position: absolute;
            width: auto;
            max-width: 100px;
            text-align: center;
            left: 220px;
            transition: left 0.5s ease;
        }

        .fourth {
            position: absolute;
            width: auto;
            max-width: 100px;
            text-align: center;
            left: 330px;
            transition: left 0.5s ease;
        }

        .fifth {
            position: absolute;
            width: auto;
            max-width: 100px;
            text-align: center;
            left: 440px;
            transition: left 0.5s ease;
        }

        .sixth {
            position: absolute;
            width: auto;
            max-width: 100px;
            text-align: center;
            left: 550px;
            transition: left 0.5s ease;
        }

        .seventh {
            position: absolute;
            width: auto;
            max-width: 100px;
            text-align: center;
            left: 660px;
            transition: left 0.5s ease;
        }

        .eighth {
            position: absolute;
            width: auto;
            max-width: 100px;
            text-align: center;
            left: 770px;
            transition: left 0.5s ease;
        }
    }
}