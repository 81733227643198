.level-info {
    margin-top: 17px;
    font-size: 17px;

    &__level {
        display: flex;
        align-items: center;
        &__circle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 7px;

            &--candidate {
                background-color: $candidate;
            }

            &--host {
                background-color: $host-02;
            }

            &--coordinator {
                background-color: $coordinator;
            }

            &--director {
                background-color: $director;
            }

            &--captain {
                background-color: $captain;
            }
        }
    }

    &__role {
        font-weight: 600;
    }
}