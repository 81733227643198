.medals-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 500px;
    max-width: 400px;

    .core {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        mask-size: contain;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        filter {
            flood-color: currentColor;
        }

        ;


        &--candidate {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $candidate;
                }
            }
        }

        &--host {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $host-02;
                }
            }
        }

        &--coordinator {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $coordinator;
                }
            }
        }

        &--director {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $director;
                }
            }
        }

        &--captain {
            svg {
                path[fill="#4DFFDD"] {
                    fill: $captain;
                }
            }
        }

    }

    .medal-recognition {
        display: flex;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 65px;
        justify-content: center;

        &__container {
            width: 78px;
            position: absolute;
            top: -40px;
            text-align: center;
        }

        &__title {
            position: absolute;
            bottom: -17px;
        }

        &__icon {

        }
    }

    .medal-time-management {
        display: flex;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 145px;
        left: 30px;
        justify-content: flex-start;

        &__container {
            width: 78px;
            position: absolute;
            top: -40px;
            text-align: center;
        }

        &__title {
            position: absolute;
            bottom: -26px;
            left: -26px
        }

        &__icon {
        }
    }

    .medal-just-in-time {
        display: flex;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 145px;
        right: 30px;
        justify-content: flex-end;

        &__container {
            width: 78px;
            position: absolute;
            top: -40px;
            text-align: center;
        }

        &__title {
            position: absolute;
            bottom: -26px;
            right: -10px
        }

        &__icon {
        }
    }


    .candidate {
        svg {
            path[fill="#4DFFDD"] {
                fill: $candidate;
            }
        }
    }

    .host {
        svg {
            path[fill="#4DFFDD"] {
                fill: $host-02;
            }

            filter: drop-shadow(0 0 5px $host-02) drop-shadow(0 0 10px $host-02);
        }
    }

    .coordinator {
        svg {
            path[fill="#4DFFDD"] {
                fill: $coordinator;
            }

            filter: drop-shadow(0 0 5px $coordinator) drop-shadow(0 0 10px $coordinator);
        }
    }

    .director {
        svg {
            path[fill="#4DFFDD"] {
                fill: $director;
            }

            filter: drop-shadow(0 0 5px $director) drop-shadow(0 0 10px $director);
        }
    }

    .captain {
        svg {
            path[fill="#4DFFDD"] {
                fill: $captain;
            }

            filter: drop-shadow(0 0 5px $captain) drop-shadow(0 0 10px $captain);
        }
    }
}