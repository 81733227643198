.otp-field {
    display: flex;
    background-color: $blue-02;
    border-radius: 5px;
    padding: 9px 11px;
    max-width: 343px;
    gap: 10px;

    &:focus-within {
        border: 1px solid $blue-01;
    }

    input:first-child {
        margin-left: 50px;
    }


    input {
        position: relative;
        height: 31px;
        font-size: 20px;
        border: none;
        border-bottom: 2px solid rgba($white, 0.5);
        background-color: transparent;
        color: $white;
        outline: none;
        text-align: center;
        

        &:focus {
            border-color: $blue-01;

            & ~ input {
                border-color: $blue-01;
            }

            & ~ .otp-line {
                rect[fill="#D9D9D9"] {
                    fill: $blue-01;
                }
            }

            & ~ .icon {
                opacity: 1;
                color: $blue-01;
                border-color: $blue-01;
            }
        }

        &:focus-within {
            border-color: $blue-01;
        }
    }

    .icon {
        position: absolute;
        font-size: 12px;
        color: $white;
        width: 25px;
        height: 25px;
        border: 2px solid $white;
        border-radius: 5px;
        opacity: 0.5;
        padding: 5px 5px 0 0;
        text-align: center;
        margin-right: 20px;
    }

    .otp-line {
        width: 100%;
        margin-left: 23px;
    }
}