.text-field {
    display: flex;
    position: relative;
    width: 343px;
    border-radius: 8px;
    color: rgba(#FFF, 0.5);
    justify-content: center;
    flex-direction: column;

    .error {
        display: none;
    }

    &--input {
        width: calc(100% - 16px);
        height: 48px;
        background-color: $blue-02;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
        border-radius: inherit;
        padding-left: 16px;

        &.padding-36 {
            width: calc(100% - 42px);
            padding-left: 42px;
        }

        &::placeholder {
            opacity: 0.9;
        }

        &:focus {
            background-color: $blue-03;
            outline: 3px solid $blue-01;

            &~.text-field--icon {
                color: $blue-01;
                background-color: $blue-03;
            }
        }


        &:invalid:not(:placeholder-shown) {
            background-color: $blue-03;
            outline: 3px solid $error;
            opacity: 0.9;

            &::placeholder {
                display: none
            }

            &+.error {
                display: block;
                color: red;
                margin-top: 5px;
                text-align: left;
                transition: 0.5s ease-in-out;
                font-size: 14px;
            }
        }

        &:disabled {
            color: rgba(#FFF, 0.9);

            &~.text-field--icon {
                color: rgba(#FFF, 0.5);
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s;
            -webkit-text-fill-color: #fff !important;
        }

        &.outline {
            border: 2px solid $blue-01;
        }
    }

    &--icon {
        position: absolute;
        top: 14px;
        background-color: transparent;
        width: 32px;

        &__left {
            left: 10px;
        }

        &__right {
            right: 0;
        }
    }
}