.induction-day-challenge-b {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 412px;
    background: $background-secondary;
    z-index: 1;

    .paragraph--s {
        position: relative;
        max-width: 375px;
    }

    img {
        position: relative;
        margin-top: 100px;
    }

    .paragraph--xs {
        position: relative;
        margin-top: 120px;
    }

    .button {
        position: relative;
        z-index: 1;
        bottom: 40px;
    }

    .card {
        width: 323px;
        min-height: 300px;
        overflow: hidden;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 56px;

        .button {
            position: relative;
            width: 100%;
        }

        &+.paragraph {
            margin-bottom: 103px;
        }
    }

    #step-3 {
        .paragraph--xs {
            margin-top: 0;
            margin-bottom: 0;

            &+.paragraph {
                margin-bottom: 103px;
            }
        }
    }

    .portal-bridge-tutorial {
        margin-top: 0;
        margin-bottom: 56px;

        &+.paragraph {
            margin-bottom: 103px;
        }
    }

    .challenge-tutorial {
        margin-top: 0;
    }

    .info-challenge-tutorial {
        margin-top: 0;
        margin-bottom: 56px;
    }

    .good-hand {
        margin-top: 0;
    }

    .epa-schedule-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 375px;
        padding-bottom: 24px;
        margin-top: 20px;

        &__key {
            width: 245px !important;
            background-color: $blue-03;
            border-radius: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            padding: 9px 9px !important;
            margin-bottom: 21px;
        }

        .paragraph {
            margin-bottom: 7.5px;
            font-size: 16px;

            b {
                font-weight: 600;
            }

            &--xs {
                font-size: 14px;
                margin-top: 10px;
            }

            .icon-info {
                position: relative;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $blue-01;
                font-size: 17px;
                opacity: 1;
                z-index: 1;
            }
        }
    }

}

.video-b-bridge{
    width: 83%;
    height: auto;
    border-radius: 18px;
    border: 1px solid #35435e;
}
.video-tutorial{
    height: 282px;
    border-radius: 18px;
    border: 1px solid #35435e;
}