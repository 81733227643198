.host-first-day-container {
    
    .checkbox {
        width: calc(80% - 20px);
        max-width: 313px;
        margin-left: 40px;
        margin-top: 20px;

        &+.button {
            position: relative !important;
            margin-top: 100px;
        }
    }

    .text-area {
        width: 90%;
        margin-top: 40px !important;
    }

    .button {
        position: relative !important;
        margin-top: 79px;
    }

    .paragraph.wishes-paragraph {
        .wishes-list {
            list-style: none;
            padding: 0;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 11px;

                &::before {
                    content: '\e802';
                    margin-right: 10px;
                    font-family: "acdc-font-icons", "Courier New", sans-serif; 
                    font-size: 8px;
                    color: $blue-01;
                }
            }
        }
    }

    .paragraph {
        h3 {
            margin-bottom: 0;
        }
    }

    .feelings-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $blue-03;
        border-radius: 15px;
        padding: 27px 0;

        .paragraph {
            h3 {
                margin: 0;
            }
        }
    }

    .aspirants-container {
        .aspirants-number {
            display: flex;
            flex-direction: row;
            align-items: center;

            .checkbox {
                width: 10px;
                padding-right: 6px;
                margin-left: 0;
            }

            .aspirants-label {
                padding: 7px 10px;
                min-width: 283px;
                margin-top: 20px;
                background-color: $blue-03;
                border-radius: 6px;

                &.hidden {
                    color: $blue-03;
                }
            }
        }
    }

    &-card {
        margin-bottom: 40px !important;
    }

    &-button {
        margin-top: 40px !important;
        bottom: 40px !important;
    }
}