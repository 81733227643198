/* Colors */
$blue-01: #0063DD;
$blue-02: #0F1B2B;
$blue-03: #0D233B; 
$blue-04: #202D47;
$blue-05: #1A2233;
$blue-07: #0E1E32;
$blue-08: #004BA8;
$blue-opacity: #0063dd80;
$blue-link: #1179FA;
$blue-circle: #173557;
$black: #000000;
$white: #FFFFFF;

/* Role Colors */
$candidate: #4DFFDD;
$candidate-circle: #313131;
$host: #6F6F76;
$host-02: #FFF;
$coordinator: #FF3434;
$director: #FFEB00;
$captain: $blue-01;

/* Success and Error Colors */
$success: #00D3A3;
$error: #FF5F5F;


/* Gradient Colors */
$main-color: #122344;
$second-color: #0D1C33;



/* Background Colors */
$background-default: linear-gradient(180deg, #0C2744 0%, #10151F 19.27%, #10151F 72.4%, #101743 100%);
$background-circle-secondary: linear-gradient(180deg, #09152D 0%, #10151F 44.79%, #3A189C 100%);
$background-secondary: linear-gradient(180deg, #09152D 100%, #10151F 100%, #3A189C 100%);
$background-stars: url('../images/background-stars.png');
$background-default-logo: url('../images/default-logo.png');
$background-bar-color: #35435E;
$background-medals-core: url('../images/medals-core.svg');
$background-medals-recognition: url('../images/recognition-medal-new.svg');
$background-medals-just-in-time: url('../images/just-in-time-medal.svg');
$background-medals-time-management: url('../images/time-management-medal.svg');


/* Images */
$avatar-candidate: url('../images/avatar-candidate.png');
$avatar-icon: url('../images/avatar-icon.svg');
$main-logo: url('../images/main-logo.svg');
$main-logo-layer: url('../images/main-logo-layer.svg');
$purpose-icon: url('../images/purpose.png');
$destination-icon: url('../images/destination.png');
$legacy-icon: url('../images/legacy.png');
$mic-image: url('../images/mic-image.png');
$mic-image-136-148: url('../images/mic-image-136-148.png');
$list-box: url('../images/list-box.png');
$list-box-136-148: url('../images/list-box-136-148.svg');
$calendar: url('../images/calendar.png');
$calendar-136-148: url('../images/calendar-136-148.svg');
$coordinator-calendar: url('../images/coordinator-calendar.png');
$coordinator-calendar-136-148: url('../images/calendar-coordinator.png');
$coordinator-sync: url('../images/coordinator-sync.png');
$coordinator-sync-136-148: url('../images/sync-coordinator.png');
$sync: url('../images/sync-icon.png');
$sync-136-148: url('../images/sync-136-148.svg');
$light: url('../images/light.png');
$greet-candidate: url('../images/greet.png');
$greet-coordinator: url('../images/greet-coordinator.png');
$greet-director: url('../images/greet-director.png');
$host-money-hand: url('../images/money-hand-host-mission.png');
$host-screen-tools: url('../images/screen-tools-host-mission.png');
$host-mail: url('../images/email-host-mission.png');
$host-money-hand-136-148: url('../images/money-hand.png');
$host-screen-tools-136-148: url('../images/host-screen-tools.png');
$host-mail-136-148: url('../images/host-mail.png');
$host-key-hand: url('../images/key-hand-mission.png');
$rocket-icon: url('../images/rocket-icon.png');
$victory-hand: url('../images/victory-hand.png');
$victory-hand-136-148: url('../images/victory-hand-136-148.png');
$rocket-icon-136-148: url('../images/rocket-icon-136-148.png');
$host-key-hand-136-148: url('../images/key-h1-host-modal.png');
$coordinator-hand-key: url('../images/key-hand-coordinator.png');
$coordinator-hand-key-136-148: url('../images/key-hand-coordinator-136-148.png');
$coordinator-screen-tools: url('../images/coordinator-tools.png');
$touch-screen-coordinator: url('../images/touch-screen-coordinator.png');
$book: url('../images/book.png');
$badge-target: url('../images/badge-target.png');
$megaphone: url('../images/megaphone.png');

$good-hand: url('../images/good-hand.png');
$check-icon: url('../images/check-icon.png');
$check-icon-136-148: url('../images/green-check.png');



/* Portal Bridge Images */
$portal-bridge-origin-platform: url('../images/portal-bridge-origin-platform.svg');
$portal-bridge: url('../images/portal-bridge.svg');


/* Tab Colors */
$tab-default: #041021;
$tab-text-color-default: rgba(#FFF, 0.5);
$tab-text-color-active: #FFF;
$tab-active: #3C5575;

$green: #00D3A3;
$validated: #00FFB2;
$sent:#FF7B51;
$noSent: #CD1F3E;
