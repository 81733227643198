.register-candidate-container {
    height: 100%;

    header {
        display: flex;
        position: relative;
        background-color: $blue-02;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        margin: 0;
        width: 100%;
        height: 301px;
        max-width: 450px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;

        .background__content__title {
            font-size: 16px;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 5px;
            line-height: 22px;
        }

        .background__content__subtitle {
            font-size: 36px;
            font-weight: 600;
            margin: 0;
        }
    }

    .form {
        align-items: center;
        min-width: 380px
    }
}