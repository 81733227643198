.purchase-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
   
    padding: 2rem;
    border-radius: 1rem;
    max-width: 300px;
    color: #ffffff; // Color de texto blanco
    margin-right: 48px;
    .info-item {
      display: flex;
      align-items: center;
      gap: 1rem;
  
      .icon {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1e2538; // Fondo del icono
        border-radius: 50%;
  
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
  
      .details {
        display: flex;
        flex-direction: column;
        background-color: #13253b;
        width: 200px;
        border-radius: 5px;
        align-items: center;
        height: 44px;
        justify-content: space-around;
        label {
          font-size: 0.9rem;
          color: #b0b3c6; // Texto secundario (etiquetas)
        }
  
        span {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff; // Texto principal
        }
      }
    }
  }
  

  .card-list-alignament{
    display: flex;
        padding-left: 20px;
        height: 53px;
        background: #0F1B2B;
        border-radius: 6px;
        /* height: 53px; */
        max-width: 234px;
        width: 288px;
        padding-top: 11px;
}
.text-list{
  padding-left: 5px;
}
.user-card-host-by-coordinator{
  background-color: #253143!important;
  border-radius: 15px !important;
}
.candidate-card{
  background-color: #0F1B2B!important;
  border-radius: 15px !important;
}
.add-candidate-text{
  text-decoration: underline!important;
  color: #00B2FF!important;
}