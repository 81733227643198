.modal-container {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.7);
    background-size: cover;
    z-index: 2!important;
    justify-content: center;

    @media(max-height: 700px) {
        height: 105%;
    }


    &.is-active {
        display: flex;
    }

    .modal {
        display: flex;
        position: relative;
        background: $background-default;
        width: 343px;
        z-index: 3;
        border-radius: 10px;
        margin-top: 20px;
        overflow: hidden;

        &-background {
            position: absolute;
            background: $background-stars;
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }

        .circle {
            position: absolute;
            width: 884px;
            height: 617px;
            background-color: $blue-03;
            border-radius: 50%;
            top: -475px;
            left: -413px;
            z-index: 3;

            &.secondary {
                background: $background-circle-secondary;
                opacity: 0.5;

            }
        }

        &-content {
            position: absolute;
            z-index: 4;
        }
    }
}

.color-gray{
  color:#b0b0b07a;
}