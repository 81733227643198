#header {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 1;
    margin: 0;
    padding: 73px 37px 0;
    max-width: none;
    justify-content: center;

    .icon-btn-menu {
        right: none;
        left: calc(100% - 84px);
    }

    &.transparent {
        background-color: transparent;

        & + .title {
            background-color: transparent;
            width: 100%;

            & + .tabs {
                background-color: transparent;
            }
        }
    }

    &.primary {
        background-color: $blue-02;

        & + .title {
            background-color: $blue-02;
            width: 100%;
            margin: 0;
            padding: 10px 0 0;
            z-index: 1;

            & + .tabs {
                padding-top: 20px;
                background-color: $blue-02;
            }

            & + .subtitle {
                background-color: $blue-02;
                width: 100%;
                opacity: 1;
                z-index: 1;
                text-align: center;
                padding-bottom: 13px;
                margin-bottom: 0;
            }
        } 
    }
}