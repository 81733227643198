.scale {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 375px;
    height: 401px;
    align-self: center;
    top: 10px;

    &__flow {
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: column;
        transform: rotate(-90deg);
        gap: 7px;
    }

    &__line {
        display: flex;
        width: 100%;
        bottom: 0;
        font-size: 17px;
        font-weight: 300;
        color: #FFF;
        flex-direction: row;
        justify-content: center;

        .line {
            position: relative;
            width: 23%;
            background-color: transparent;
            top: 10%;
            height: 10px;

            &.left {
                margin-right: 13px;
                border-bottom: 1px solid #FFF;
                border-left: 1px solid #FFF;
                border-bottom-left-radius: 16px;
            }

            &.right {
                margin-left: 13px;
                border-bottom: 1px solid #FFF;
                border-right: 1px solid #FFF;
                border-bottom-right-radius: 16px;
            }
        }
    }
}