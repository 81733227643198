.induction-day-page {
    height: 100%;
    overflow: hidden;

    .title {
        margin-bottom: 0;
    }

    .subtitle {
        margin: 0 0 10px;
        font-size: 18px;
        opacity: 0.5;
    }
}