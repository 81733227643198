.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 27px;


    &.fixed {
        
        .button {
            position: absolute;
            bottom: 40px;
        }

    }

    .button {
        margin-bottom: 40px;
    }

    
}