.card {
    margin-bottom: 127px;
}


.phrase-container {
    bottom: 127px;
}

.drag-select {
    margin-bottom: 150px;
}

.good-hand+.paragraph {
    margin-bottom: 190px;
}

.rules-day-c {

    &#step-1 {
        .card {
            margin-bottom: 20px !important;

            & + .paragraph {
                margin-bottom: 5px;


                & + .group-radio {
                    margin-bottom: 123px;
                }
            }
        }
    }

    &#step-3 {
        .paragraph {
            margin-bottom: 5px;

            & + .group-radio {
                margin-bottom: 123px;
            }
        }
    }

    .group-radio {
        margin-bottom: 20px;
        & + .button {
            position: relative !important;
        }
    }
}

.rules-day-container {
    img {
        & + .button {
            position: relative !important;
        }
    }

    .group-radio {
        margin-bottom: 20px;
        & + .button {
            position: relative !important;
        }
    }

    .paragraph{
        &.title-quest {
            position: relative;
            width: 67%;
        }

        & + .button {
            position: relative !important;
        }
    }

    &-card {
        margin-bottom: 40px !important;
    }

    &-button {
        position: relative !important;
    }
}