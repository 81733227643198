.validator-item {
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: $blue-05;
    border-radius: 8px;
    align-items: center;
    width: 343px;
    z-index: 1;
    margin-top: 8px;
    margin-bottom: 8px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &__border {
        width: 19px;
        position: relative;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin-right: 14px;
        align-self: stretch;
        
        &.candidate {
            background-color: $candidate;
        }

        &.host {
            background-color: $host-02;
        }

        &.coordinator {
            background-color: $coordinator;
        }

        &.director {
            background-color: $director;
        }

        &.captain {
            background-color: $captain;
        }
    }

    &__content {
        padding: 14px 0;

        .index {
            font-weight: 700;
        }

        .menu-user-info {
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .menu-user-info-avatar {
                width: 85px;
                height: 85px;

                svg {
                    width: calc(100% + 21px);
                    height: calc(100% + 21px)
                }
            }

            .menu-user-info-title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;
                
                span {
                    color: #FFF !important;
                    font-weight: 300;
                }
            }

            .extra-content {
                margin-top: 10px;
            }
        }

        &.candidate {

            span.icon-recognition {
                color: $candidate !important;
            }
        }

    }


    .right {
        position: absolute;
        transform: rotate(180deg);
        font-size: 32px;
        right: 0;

        &.candidate {
            color: $candidate;
        }

        &.host {
            color: $host-02;
        }

        &.coordinator {
            color: $coordinator;
        }

        &.director {
            color: $director;
        }

        &.captain {
            color: $captain;
        }

    }
}