.container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #0d1425;
    color: white;
  
    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
        text-align: center;
      }
  
      .rank {
        display: flex;
        flex-direction: column;
        gap: 15px;
  
        .rank-item {
          display: flex;
          align-items: center;
          gap: 10px;
  
          .rank-number {
            font-size: 1.5rem;
            font-weight: bold;
          }
  
          .details {
            display: flex;
            align-items: center;
            gap: 10px;
  
            p {
              font-size: 1rem;
              margin: 0;
            }
  
            .badge {
              display: inline-block;
              padding: 5px 10px;
              font-size: 0.9rem;
              font-weight: bold;
              color: #00ffba;
              border: 2px solid #00ffba;
              border-radius: 5px;
              background-color: transparent;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  